import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Modal } from "antd";
import {
  getAllToppings,
  selectToppings,
  activeTopping,
  deleteMonitorTopping,
  handlePrice,
  handleOrderToppings,
  handleOrderCategories,
} from "../../redux/slices/toppings";
import { getAllBranches, selectBranches } from "../../redux/slices/branches";
import ToppingsUI from "./components/ToppingsUI";

const Toppings = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [modalPrice, handleModalPrice] = useState(false);
  const [topping, handleTopping] = useState(null);
  const [category, handleCategory] = useState(null);
  const [branch, handleBranch] = useState(null);
  const toppings = useSelector(selectToppings);
  const branches = useSelector(selectBranches);
  const dispatch = useDispatch();

  const getToppings = useCallback(
    async (selectedBranch) => {
      handleLoading(true);
      const response = await dispatch(getAllToppings(selectedBranch));
      if (response.status !== "success") {
        message.error("¡Hubo un problema!");
      }
      handleLoading(false);
    },
    [dispatch]
  );

  const initialFetch = useCallback(async () => {
    handleLoading(true);
    const response_branches = await dispatch(getAllBranches());
    if (response_branches.status === "success") {
      handleBranch(response_branches.first);
      await getToppings(response_branches.first);
    } else {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dispatch, getToppings]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const editTopping = (newTopping, newCategory) => {
    handleTopping(newTopping);
    handleCategory(newCategory);
    handleEditorVisible(true);
  };

  const closeEditor = () => {
    handleEditorVisible(false);
    handleTopping(null);
    handleCategory(null);
  };

  const newTopping = (newCategory) => {
    handleTopping(null);
    handleCategory(newCategory);
    handleEditorVisible(true);
  };

  const openModalPrice = () => {
    handleModalPrice(true);
  };

  const closeModalPrice = () => {
    handleModalPrice(false);
  };

  const handleActive = async (idTopping, indexCategory, indexTopping, val) => {
    const response = await dispatch(
      activeTopping(idTopping, indexCategory, indexTopping, val)
    );
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  const confirmDelete = (idTopping, indexCategory, indexTopping) => {
    Modal.confirm({
      title: "¿Estás segúro de eliminar este topping?",
      content: "Esta información no se podrá recuperar.",
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        await removeTopping(idTopping, indexCategory, indexTopping);
      },
      onCancel() {},
    });
  };

  const removeTopping = async (idTopping, indexCategory, indexTopping) => {
    const response = await dispatch(
      deleteMonitorTopping(idTopping, indexCategory, indexTopping)
    );
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Topping eliminado correctamente");
    }
  };

  const changePrice = async (price) => {
    const response = await dispatch(handlePrice(price));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Precios actualizados");
    }
  };

  const selectBranch = (e) => {
    handleBranch(e);
    getToppings(e);
  };

  const reorderToppings = async ({ draggableId, destination, source }) => {
    const id = draggableId.split("-")[1];
    const nextIndex = destination.index;
    const prevIndex = source.index;
    const categoryId = destination.droppableId.split("-")[1];
    const indexCategory = toppings.findIndex(
      (el) => el.id === parseInt(categoryId, 10)
    );
    const oldList = toppings[indexCategory].toppings;
    const response = await dispatch(
      handleOrderToppings(nextIndex, id, prevIndex, indexCategory, oldList)
    );
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Cambios actualizados");
    }
  };

  const reorderCategories = async ({ draggableId, destination, source }) => {
    const id = draggableId.split("-")[1];
    const nextIndex = destination.index;
    const prevIndex = source.index;
    const response = await dispatch(
      handleOrderCategories(nextIndex, id, prevIndex, toppings)
    );
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Cambios actualizados");
    }
  };

  return (
    <ToppingsUI
      loading={loading}
      toppings={toppings}
      topping={topping}
      category={category}
      editorVisible={editorVisible}
      editTopping={editTopping}
      newTopping={newTopping}
      closeEditor={closeEditor}
      handleActive={handleActive}
      confirmDelete={confirmDelete}
      handleTopping={handleTopping}
      modalPrice={modalPrice}
      openModalPrice={openModalPrice}
      closeModalPrice={closeModalPrice}
      changePrice={changePrice}
      branches={branches}
      branch={branch}
      selectBranch={selectBranch}
      reorderToppings={reorderToppings}
      reorderCategories={reorderCategories}
    />
  );
};

export default Toppings;
