import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {Row, Col, Modal, DatePicker, Table, Button, Spin } from 'antd';
import styles from '../styles/Statistics.module.css';
import { getColumnSearchProps } from '../../../utils/tables';
import KPI_alza from "../../../assets/icons/kpi_alza.svg";
import KPI_baja from "../../../assets/icons/kpi_baja.svg";
import usuarios_registrados from "../../../assets/icons/usuarios_registrados.svg";
import usuarios_activos from "../../../assets/icons/usuarios_activos.svg";
import ordenes_realizadas from "../../../assets/icons/ordenes_realizadas.svg";
import { getKPI_RepurchaseReport } from '../../../api/kpi';


const RepurchaseReport = () => {

    //Declarations
    const { RangePicker } = DatePicker;
    const initalObj = {type:"number", val:"0", location: []};

    //useState
    const [objQuery, setobjQuery] = useState(initalObj);
    const [loading, handleLoading] = useState(false);
    const [showModal, handleModal] = useState(false);
    const [datosClientes, setdatosClientes] = useState([]);
    const [ranges, setranges] = useState(null);
    const [listaClientes, setListaClientes] = useState([]);
    const [titleModal, setTitleModal] = useState("Listado de Clientes");
    const [variacionClientes, setvariacionClientes] = useState({actual:0, porcien: 0, clientes: []});
    const [varSegundoPedido, setvarSegundoPedido] = useState({actual:0, porcien: 0, clientes: []});
    const [varTercerPedido, setvarTercerPedido] = useState({actual:0, porcien: 0, clientes: []});
    const [varCuartoPedido, setvarCuartoPedido] = useState({actual:0, porcien: 0, clientes: []});

    //useEffect
    useEffect(() => {
        getKPI_RepurchaseReport(objQuery).then(response => {
            if(response.status === "success"){
                setdatosClientes(response.cliente);
                let datosVarClts = response.ordenes;

                setvariacionClientes(datosVarClts.nuevos);
                setvarSegundoPedido(datosVarClts.segundoPedido);
                setvarTercerPedido(datosVarClts.tercerPedido);
                setvarCuartoPedido(datosVarClts.cuartoPedido);
                
            } else {
                alert("No se procesó la información.");
            }
            handleLoading(false);
        })
  
    }, [objQuery])

    //Others declarations
    const formatKPI = (x) => {
        let kpiformat = 0;
        if(!(x === null || x === "" || x === undefined)){
            kpiformat = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }else{
            kpiformat = 0;
        }
        return kpiformat;
    }

    const formatABS = (x) => {
        let absformat = 0;
        if(!(x === null || x === "" || x === undefined)){
            absformat = Math.abs(x);
        }else{
            absformat = 0;
        }
        return absformat;
    }

    const GetRanges = (dateString) => {
        let date1 = dateString[0].format('DD-MM-YYYY');
        let date2 = dateString[1].format('DD-MM-YYYY');
        let ranges = [date1, date2];
        setranges(dateString);
        //if(!(dateString === null)){
            let new_objQuery = {type:"range", val:ranges, location: [5]};
            setobjQuery(new_objQuery);
            handleLoading(true);

            //getKPI_RepurchaseReport(objQuery).then(response => {
            //    if(response.status === "success") {
            //        setdatosClientes(response.cliente);
            //        let datosVarClts = response.ordenes;
            //        setvariacionClientes(datosVarClts.nuevos);
            //        setvarSegundoPedido(datosVarClts.segundoPedido);
            //        setvarTercerPedido(datosVarClts.tercerPedido);
            //        setvarCuartoPedido(datosVarClts.cuartoPedido);
            //    } else {
            //        alert("No se procesó la información.");
            //    }
            //})
        //}
    }

    const showClients = (event) => {
        console.log(event.target.parentElement);
        //sacamos el id
        const idCli = Number(event.target.parentElement.getAttribute("data-order-count"));
        if(idCli === 1) {
            setTitleModal("Listado Clientes Nuevos");
            setListaClientes(variacionClientes.clientes);
        } 
        else if (idCli === 2) {
            setTitleModal("Listado Clientes Realizado 2 Compras");
            setListaClientes(varSegundoPedido.clientes);
        }
        else if (idCli === 3) {
            setTitleModal("Listado Clientes Realizado 3 Compras");
            setListaClientes(varTercerPedido.clientes);
        }
        else if (idCli === 4) {
            setTitleModal("Listado Clientes Realizado 4 o mas Compras");
            setListaClientes(varCuartoPedido.clientes);
        }
        handleModal(true);
    }

    const handleCancel = () => {
        setListaClientes([]);
        handleModal(false);
    }

    const clientColumns = [
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'center',
        },
        {
          title: 'Nombre',
          dataIndex: 'nombre',
          ...getColumnSearchProps('nombre'),
        },
        {
          title: 'Apellidos',
          dataIndex: 'apellido',
          ...getColumnSearchProps('apellido'),
        },
        {
          title: 'Acciones',
          align: 'center',
          dataIndex: 'id',
          render: (id, record) => (
            <div className={styles.actions}>
              <Link to={`/ajustes/clientes/${record.id}`}>Perfil »</Link>
            </div>
          ),
        },
      ];

    return(
        <div>
            <div className={styles.titulo_reportes}>seguimiento de clientes</div>
            <div className={styles.mb_10}>
                <Row className={styles.contenedor_filtros+" "+styles.justify_content_end}>
                    <Col>
                        <div>
                            <RangePicker 
                              value={ranges}
                              onChange={GetRanges}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            {
                loading ? (
                    <div className={styles.loader}><Spin size="large" /></div>
                ) : (
                    <div>
                        <Row style={{ justifyContent: 'center', alignItems: 'center'}}>
                            <Col span={8}>
                                <div className={styles.circles_kpi}>
                                    <div><img className={styles.circles_kpi_img} src={usuarios_registrados} alt='' /></div>
                                    <div className={styles.font_siza_56_primary}>{formatKPI(datosClientes.totalRegistrados)}</div>
                                    <div className={styles.circle_sub_title}>usuarios registrados</div>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className={styles.circles_kpi}>
                                    <div><img className={styles.circles_kpi_img} src={usuarios_activos} alt='' /></div>
                                    <div className={styles.font_siza_56_primary}>{formatKPI(datosClientes.totalActivos)}</div>
                                    <div className={styles.circle_sub_title}>usuarios activos</div>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className={styles.circles_kpi}>
                                    <div><img className={styles.circles_kpi_img} src={ordenes_realizadas} alt='' /></div>
                                    <div className={styles.font_siza_56_primary}>{formatKPI(datosClientes.ordenesRealizadas)}</div>
                                    <div className={styles.circle_sub_title}>ordenes realizadas</div>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={0} className={styles.card}>
                            <Col span={6}>
                                <div className={styles.boxdash_container}>
                                    <div className={styles.contenedor_rr_kpi}>
                                        <div className={styles.indicador_secondary}>{variacionClientes.actual}</div>
                                        <div className={variacionClientes.porcien<0?styles.img_sub_indicador_danger:styles.img_sub_indicador_primary}>
                                            <img src={variacionClientes.porcien<0?KPI_baja:KPI_alza} alt='img' />
                                        </div>
                                        {
                                            variacionClientes.porcien < 0 ? 
                                            <div className={styles.sub_indicador_danger}>{formatABS(variacionClientes.porcien)} %</div>
                                            :
                                            <div className={styles.img_sub_indicador_primary}>{variacionClientes.porcien} %</div>
                                        }
                                    </div>                                   
                                    <div className={styles.contenedor_desc}>
                                        <p className={styles.desc_indicador}>CLIENTE NUEVO</p>
                                        <p><Button data-order-count="1" onClick={showClients}>Listado de clientes</Button></p>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className={styles.boxdash_container}>
                                    <div className={styles.contenedor_rr_kpi}>
                                        <div className={styles.indicador_secondary}>{varSegundoPedido.actual}</div>
                                        <div className={varSegundoPedido.porcien<0?styles.img_sub_indicador_danger:styles.img_sub_indicador_primary}>
                                            <img src={varSegundoPedido.porcien<0?KPI_baja:KPI_alza} alt='img' />
                                        </div>
                                        {
                                            varSegundoPedido.porcien < 0 ? 
                                            <div className={styles.sub_indicador_danger}>{formatABS(varSegundoPedido.porcien)} %</div>
                                            :
                                            <div className={styles.img_sub_indicador_primary}>{varSegundoPedido.porcien} %</div>
                                        }
                                    </div>
                                    <div className={styles.contenedor_desc}>
                                        <p className={styles.desc_indicador}>SEGUNDO PEDIDO</p>
                                        <p><Button data-order-count="2" onClick={showClients}>Listado de clientes</Button></p>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className={styles.boxdash_container}>
                                    <div className={styles.contenedor_rr_kpi}>
                                        <div className={styles.indicador_secondary}>{varTercerPedido.actual}</div>
                                        <div className={varTercerPedido.porcien<0?styles.img_sub_indicador_danger:styles.img_sub_indicador_primary}>
                                            <img src={varTercerPedido.porcien<0?KPI_baja:KPI_alza} alt='img' />
                                        </div>
                                        {
                                            varTercerPedido.porcien < 0 ? 
                                            <div className={styles.sub_indicador_danger}>{formatABS(varTercerPedido.porcien)} %</div>
                                            :
                                            <div className={styles.img_sub_indicador_primary}>{varTercerPedido.porcien} %</div>
                                        }
                                    </div>
                                    <div className={styles.contenedor_desc}>
                                        <p className={styles.desc_indicador}>TERCER PEDIDO</p>
                                        <p><Button data-order-count="3" onClick={showClients}>Listado de clientes</Button></p>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className={styles.boxdash_container}>
                                    <div className={styles.contenedor_rr_kpi}>
                                        <div className={styles.indicador_secondary}>{varCuartoPedido.actual}</div>
                                        <div className={varCuartoPedido.porcien<0?styles.img_sub_indicador_danger:styles.img_sub_indicador_primary}>
                                            <img src={varCuartoPedido.porcien<0?KPI_baja:KPI_alza} alt='img' />
                                        </div>
                                        {
                                            varCuartoPedido.porcien < 0 ? 
                                            <div className={styles.sub_indicador_danger}>{formatABS(varCuartoPedido.porcien)} %</div>
                                            :
                                            <div className={styles.img_sub_indicador_primary}>{varCuartoPedido.porcien} %</div>
                                        }
                                    </div>
                                    <div className={styles.contenedor_desc}>
                                        <p className={styles.desc_indicador}>CUARTO PEDIDO</p>
                                        <p><Button data-order-count="4" onClick={showClients}>Listado de clientes</Button></p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )
            }
            <Modal
                    destroyOnClose
                    title={titleModal}
                    footer={false}
                    visible={showModal}
                    onCancel={handleCancel}
                    width={600}>
                    <Table
                            columns={clientColumns}
                            dataSource={listaClientes}
                            rowKey="id"
                        />
                </Modal>
        </div>
    )
};

export default RepurchaseReport;