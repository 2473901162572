import instance from './Request';

export const getKPIResumen = async obj => {
    const request = await instance();
    let data = await request
        .post('/main/kpi-resumen', obj)
        .catch((error) =>{
            return {
                error
            };
        });
    return data.data;
}

export const getKPITopProducts = async obj => {
    const request = await instance();
    let data = await request
        .post('/main/kpi-productos', obj)
        .catch((error) =>{
            return {
                error
            };
        });
    return data.data;
}

export const getKPIAverageTimes = async obj => {
    const request = await instance();
    let data = await request
        .post('/main/kpi-tiempos', obj)
        .catch((error) =>{
            return {
                error
            };
        });
    return data.data;
}

export const getKPIOrdersByZones = async obj => {
    const request = await instance();
    let data = await request
        .post('/main/kpi-zonas', obj)
        .catch((error) =>{
            return {
                error
            };
        });
    return data.data;
}

export const getKPI_SummaryOfTotals = async obj => {
    const request = await instance();
    let data = await request
        .post('/main/kpi-totales', obj)
        .catch((error) =>{
            return {
                error
            };
        });
    return data.data;
}

export const getKPI_HourlyOrders = async obj => {
    const request = await instance();
    let data = await request
        .post('/main/kpi-horas', obj)
        .catch((error) =>{
            return {
                error
            };
        });
    return data.data;
}

export const getKPI_OrdersAndSales = async obj => {
    const request = await instance();
    let data = await request
        .post('/main/kpi-ventas', obj)
        .catch((error) =>{
            return {
                error
            };
        });
    return data.data;
}

export const getKPI_RepurchaseReport = async obj => {
    const request = await instance();
    let data = await request
        .post('/main/kpi-clientes', obj)
        .catch((error) =>{
            return {
                error
            };
        });
    return data.data;
}

export const getLocations = async ()  => {
    const request = await instance();
    let data = await request
        .get('/location')
        .catch((error) =>{
            return {
                error
            };
        });
    return data;
}
