import {createSlice} from '@reduxjs/toolkit';
import {
  getSlides,
  newBanner,
  editBanner,
  deleteBanner,
  changeActive,
} from '../../api/Frontpage';

export const frontpageSlice = createSlice({
  name: 'frontpage',
  initialState: {
    slides: [],
  },
  reducers: {
    setSlides: (state, action) => {
      state.slides = action.payload;
    },
    setBanner: (state, action) => {
      const index = state.slides.findIndex(el => el.id === action.payload.id);
      if (index > -1) {
        state.slides[index] = action.payload;
      }
    },
    addBanner: (state, action) => {
      state.slides.push(action.payload);
    },
    removeBanner: (state, action) => {
      const index = state.slides.findIndex(el => el.id === action.payload);
      if (index > -1) {
        state.slides.splice(index, 1);
      }
    },
    setActive: (state, action) => {
      const {index, val} = action.payload;
      state.slides[index].active = val;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setSlides, setBanner, addBanner, removeBanner, setActive} = frontpageSlice.actions;

export const getAllSlides = () => async dispatch => {
  try {
    const response = await getSlides();
    if (!response.error && response.status === 200) {
      dispatch(setSlides(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const activeBanner = (id, index, val) => async dispatch => {
  try {
    dispatch(setActive({index, val: !val}));
    const response = await changeActive(id);
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
      };
    }
    dispatch(setActive({index, val}));
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    dispatch(setActive({index, val}));
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const updateSlide = (id, data) => async dispatch => {
  try {
    const response = await editBanner(id, data);
    if (!response.error && response.status === 200) {
      dispatch(setBanner(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const createSlide = data => async dispatch => {
  try {
    const response = await newBanner(data);
    if (!response.error && response.status === 200) {
      dispatch(addBanner(response.data))
      return {
        status: 'success',
        banner: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const deleteSlide = id => async dispatch => {
  try {
    const response = await deleteBanner(id);
    if (!response.error && response.status === 200) {
      dispatch(removeBanner(id));
      return {
        status: 'success',
        // departments: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const selectSlides = state => state.frontpage.slides;

export default frontpageSlice.reducer;
