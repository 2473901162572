import { Button, Table, Switch, Spin, Modal } from "antd";
import Header from "../../../components/HeaderList";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import Editor from "./Editor";
import Schedule from "./Schedule";
import styles from "../styles/BranchesUI.module.css";

const BranchesUI = (props) => {
  const {
    loading,
    branches,
    branch,
    openEditor,
    editorVisible,
    closeEditor,
    departments,
    initialFetch,
    handleDelivery,
    newBranch,
    handleBranch,
    scheduleVisible,
    setScheduleVisible,
    times,
    setTimes,
    updateSchedule,
  } = props;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Municipio",
      dataIndex: "locality",
      align: "center",
      render: (locality) => locality.name,
    },
    {
      title: "Editar",
      align: "center",
      dataIndex: "id",
      render: (id, record) => (
        <div className={styles.edit} onClick={() => openEditor(record)}>
          <Edit />
        </div>
      ),
    },
    {
      title: "Para delivery",
      align: "center",
      dataIndex: "delivery",
      render: (delivery, record) => (
        <Switch
          checked={delivery}
          onChange={() => handleDelivery(record.id, delivery)}
        />
      ),
    },
  ];

  return (
    <div className={styles.view}>
      <Header
        title="Tiendas"
        back="/contenidos"
        actions={
          <div>
            <Button
              onClick={() => setScheduleVisible(true)}
              type="primary"
              size="large"
              className={styles.hoursBtn}
            >
              HORARIO DELIVERY
            </Button>
            <Button
              onClick={newBranch}
              type="primary"
              size="large"
              className="main-button"
            >
              NUEVA TIENDA
            </Button>
          </div>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table columns={columns} dataSource={branches} rowKey="id" />
        </div>
      )}
      <Modal
        destroyOnClose
        closable={false}
        visible={editorVisible}
        footer={false}
        onCancel={closeEditor}
        width={950}
      >
        <Editor
          close={closeEditor}
          branch={branch}
          departments={departments}
          initialFetch={initialFetch}
          handleBranch={handleBranch}
        />
      </Modal>
      <Modal
        destroyOnClose
        closable={false}
        visible={scheduleVisible}
        footer={false}
        onCancel={() => setScheduleVisible(false)}
        width={400}
      >
        <Schedule
          close={() => setScheduleVisible(false)}
          times={times}
          setTimes={setTimes}
          updateSchedule={updateSchedule}
        />
      </Modal>
    </div>
  );
};

export default BranchesUI;
