import { createSlice } from "@reduxjs/toolkit";
import {
  getProducts,
  newProduct,
  deleteProduct,
  editProduct,
  getAnimations,
} from "../../api/Products";
import { getCategories } from "../../api/Categories";

export const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
  },
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setProduct: (state, action) => {
      const { category, product, oldCategory } = action.payload;
      const indexCategory = state.products.findIndex(
        (el) => el.id === category
      );
      if (category === oldCategory) {
        const index = state.products[indexCategory].products.findIndex(
          (el) => el.id === product.id
        );
        if (index > -1) {
          state.products[indexCategory].products[index] = product;
        }
      } else {
        const indexOldCategory = state.products.findIndex(
          (el) => el.id === oldCategory
        );
        const indexOld = state.products[indexOldCategory].products.findIndex(
          (el) => el.id === product.id
        );
        state.products[indexOldCategory].products.splice(indexOld, 1);
        state.products[indexCategory].products.push(product);
      }
    },
    addProduct: (state, action) => {
      const { category, product } = action.payload;
      const indexCategory = state.products.findIndex(
        (el) => el.id === category
      );
      state.products[indexCategory].products.push(product);
    },
    removeProduct: (state, action) => {
      const { category, product } = action.payload;
      const indexCategory = state.products.findIndex(
        (el) => el.id === category
      );
      const index = state.products[indexCategory].products.findIndex(
        (el) => el.id === product
      );
      state.products[indexCategory].products.splice(index, 1);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProducts, removeProduct, addProduct, setProduct } =
  productsSlice.actions;

export const getAllProducts = (branch) => async (dispatch) => {
  try {
    const [response_categories, response] = await Promise.all([
      getCategories(branch),
      getProducts(branch),
    ]);
    if (
      !response.error &&
      response.status === 200 &&
      !response_categories.error &&
      response_categories.status === 200
    ) {
      const categories = response_categories.data;
      response.data.map((product) => {
        const indexCategory = categories.findIndex(
          (el) => el.id === product.categoryId
        );
        if (categories[indexCategory].products) {
          categories[indexCategory].products.push(product);
        } else {
          categories[indexCategory].products = [product];
        }
      });
      dispatch(setProducts(categories));
      return {
        status: "success",
        products: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getAnimationsList = () => async () => {
  try {
    const response = await getAnimations();
    if (!response.error && response.status === 200) {
      return {
        status: "success",
        animations: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const updateProduct =
  (id, data, category, oldCategory) => async (dispatch) => {
    try {
      const response = await editProduct(id, data);
      if (!response.error && response.status === 200) {
        dispatch(setProduct({ category, product: response.data, oldCategory }));
        return {
          status: "success",
          product: response.data,
        };
      }
      return {
        status: "error",
        type: "unkown",
      };
    } catch (e) {
      return {
        status: "error",
        type: "unknown",
      };
    }
  };

export const createProduct = (data, category) => async (dispatch) => {
  try {
    const response = await newProduct(data);
    if (!response.error && response.status === 200) {
      dispatch(addProduct({ category, product: response.data }));
      return {
        status: "success",
        product: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const removeMonitorProduct = (id, category) => async (dispatch) => {
  try {
    const response = await deleteProduct(id);
    if (!response.error && response.status === 200) {
      dispatch(removeProduct({ category, product: id }));
      return {
        status: "success",
        // departments: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const selectProducts = (state) => state.products.products;

export default productsSlice.reducer;
