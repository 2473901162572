import {useState, useEffect} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  message,
  Upload,
  Switch,
} from 'antd';
import {useDispatch} from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {normFile, beforeUpload, dummyRequest} from '../../../utils/images';
import {createBlog, updateBlog} from '../../../redux/slices/blogs';
import Close from '../../../components/Close';
import styles from '../styles/Editor.module.css';

const {Option} = Select;

const EditorBlog = ({close, blog, categories, handleBlog}) => {
  const [sending, handleSending] = useState(false);
  const [image, handleImage] = useState('');
  const [content, handleContent] = useState('');
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      if (blog) {
        const newValues = {
          title: blog.title,
          category: blog.categoryId,
          description: blog.description,
          active: blog.active,
        };
        handleContent(blog.content);
        handleImage(blog.image);
        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, [blog]);

  const save = async () => {
    try {
      if (image && image.length) {
        handleSending(true);
        const values = await form.validateFields();
        const info = new FormData();
        if (blog) {
          info.append('id', blog.id);
        }
        info.append('title', values.title);
        info.append('desctiption', values.description);
        info.append('category', values.category);
        info.append('active', values.active);
        info.append('content', content);
        info.append('image', values.image?.length ? values.image[0]?.originFileObj : image);
        if (blog) {
          info.id = blog.id;
        }
        if (blog) {
          const response = await dispatch(updateBlog(blog.id, info));
          if (response.status === 'success') {
            handleSending(false);
            message.success('Cambios realizados con éxito');
          } else {
            handleSending(false);
            message.error('¡Hubo un problema! Inténtalo de nuevo');
          }
        } else {
          const response = await dispatch(createBlog(info));
          if (response.status === 'success') {
            message.success('Cambios realizados con éxito');
            handleSending(false);
            handleBlog(response.blog);
          } else {
            handleSending(false);
            message.error('¡Hubo un problema! Inténtalo de nuevo');
          }
        }
      } else {
        message.error('Seleciona una imagen válida');
      }

    } catch (e) {
    }
  };

  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>
          {blog ? 'Editar entrada' : 'Nueva entrada'}
        </span>
        <Close action={close} />
      </div>
      <Form
        name="branch-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
        initialValues={{active: true}}
      > 
        <Row gutter={20}>
          <Col span={16}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      TÍTULO
                    </span>
                  }
                  name="title"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar título'}]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      DESCRIPCIÓN
                    </span>
                  }
                  name="description"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar descripción'}]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <ReactQuill theme="snow" value={content} onChange={handleContent}/>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <span className={styles.label}>
                          CATEGORÍA
                        </span>
                      }
                      name="category"
                      className={styles.itemColumn}
                      rules={[{required: true, message: 'Ingresar categoría'}]}
                    >
                      <Select size="large" className={styles.input}>
                        {categories.map(category => (
                          <Option key={category.id} value={category.id}>{category.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  label={
                    <span className={styles.label}>
                      IMAGEN DE CABECERA
                    </span>
                  }
                  className={styles.itemColumn}
                  extra={<span className={styles.center}>(Formatos jpg o png de 1000px ancho)</span>}
                >
                <Upload
                  maxCount={1}
                  showUploadList={false}
                  customRequest={e => dummyRequest(e, handleImage)}
                  beforeUpload={beforeUpload}
                  listType="picture">
                  <Button size="large" className={styles.uploadImage}>Cambiar imagen</Button>
                </Upload>
              </Form.Item>
              <div className={styles.preview} style={{backgroundImage: `url(${image})`}} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className={styles.activeProduct}>
                  <Form.Item
                    name="active"
                    label={
                      <span className={styles.blue}>
                        Activo
                      </span>
                    }
                    valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className={styles.itemSubmit}>
              <Button
                loading={sending}
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit">
                GUARDAR ENTRADA
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default EditorBlog;
