import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import {getAllBlogs, selectBlogs, removeMonitorBlog, getAllCategories} from '../../redux/slices/blogs';
import BlogsUI from './components/BlogsUI';

const Blogs = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [categories, handleCategories] = useState([]);
  const [blog, handleBlog] = useState(null);
  const blogs = useSelector(selectBlogs);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      const [response, response_categories] = await Promise.all([
        dispatch(getAllBlogs()),
        dispatch(getAllCategories()),
      ]);
      if (response.status !== 'success') {
        message.error('¡Hubo un problema!');
      } else {
        handleCategories(response_categories.categories);
      }
      handleLoading(false);
    };
    initialFetch();
  }, [dispatch]);


  const openEditor = blogToEdit => {
    handleBlog(blogToEdit);
    handleEditorVisible(true);
  };

  const newBlog = () => {
    handleBlog(null);
    handleEditorVisible(true);
  };

  const closeEditor = () => {
    handleBlog(null);
    handleEditorVisible(false);
  };

  const confirmDelete = idBlog => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar este blog?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        await removeBlog(idBlog);
      },
      onCancel() {},
    });
  };

  const removeBlog = async idBlog => {
    const response = await dispatch(removeMonitorBlog(idBlog));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    } else {
      message.success('Entrada eliminada correctamente');
    }
  }

  return (
    <BlogsUI
      loading={loading}
      blogs={blogs}
      blog={blog}
      categories={categories}
      openEditor={openEditor}
      editorVisible={editorVisible}
      closeEditor={closeEditor}
      newBlog={newBlog}
      confirmDelete={confirmDelete}
      handleBlog={handleBlog}
    />
  );
}

export default Blogs;
