import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, message, Row, Col, Button, DatePicker } from 'antd';
import { editClient, newClient } from '../../../redux/slices/clients';
import Close from '../../../components/Close';
import styles from '../styles/Editor.module.css';
import moment from 'moment';

const { TextArea } = Input;

const ClientEditor = ({close, client}) => {

    
    const [sending, handleSending] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    useEffect(() => {
        const initialFetch = async () => {
            try {
                if (client) {
                    const newValues = {
                        name: client.name,
                        lastname: client.lastname,
                        country: client.country,
                        phone: client.phone,
                        email: client.email,
                        identity: client.identity,
                        birthday: moment(client.birthday, "DD/MM/YYYY"),
                        notes: client.notes,
                    };
                    form.setFieldsValue(newValues);
                }
            } catch (e) {
                console.log(e)
            }
        };
        initialFetch();
    }, [client, form]);

    const save = async () => {
        try {
            handleSending(true);
            const values = await form.validateFields();

            let info = {};
            if (client) {
                info.customer = client.id;
            }
            info.nombre = values.name;
            info.apellido = values.lastname;
            info.telefono = values.phone;
            info.dui = values.identity;
            info.email = values.email;
            info.cumple = moment(values.birthday).format("DD/MM/YYYY");
            info.notas = values.notes;
            if (client) {
                const response = await dispatch(editClient(client.id, info));
                if (response.status === "success") {
                    message.success('Cambios realizados con éxito');
                } else {
                    message.error('¡Hubo un problema! Inténtalo de nuevo');
                }
            } else {
                const response = await dispatch(newClient(info));
                if (response.status === 'success') {
                    message.success('Cambios realizados con éxito');
                //handleAddress(response.address);
                } else {
                    message.error('¡Hubo un problema! Inténtalo de nuevo');
                }
            }
            
    
            handleSending(false);
        } catch (e) {
            handleSending(false);
            message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
        };

    return (
        <div className={styles.editor}>
            <div className={styles.header}>
                <span className={styles.title}>
                {client ? 'Editar Cliente' : 'Nuevo Cliente'}
                </span>
                <Close action={close} />
            </div>
            <Form
                name="client-editor"
                autoComplete="off"
                form={form}
                colon={false}
                requiredMark={false}
                onFinish={save}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label={ <span className={styles.label}>NOMBRES</span> }
                            name="name"
                            className={styles.itemColumn}
                            rules={[{required: true, message: 'Ingresar nombres'}]}
                        >
                            <Input size="large" className={styles.input} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={ <span className={styles.label}>APELLIDOS</span> }
                            name="lastname"
                            className={styles.itemColumn}
                            rules={[{required: true, message: 'Ingresar nombres'}]}
                        >
                            <Input size="large" className={styles.input} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={ <span className={styles.label}>E-MAIL</span> }
                            name="email"
                            className={styles.itemColumn}
                            rules={[{required: true, message: 'Ingresar e-mail'}]}
                        >
                            <Input size="large" className={styles.input} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={ <span className={styles.label}>TELEFONO</span> }
                            name="phone"
                            className={styles.itemColumn}
                            rules={[{required: true, message: 'Ingresar telefono'}]}
                        >
                            <Input size="large" className={styles.input} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={ <span className={styles.label}>Cumpleaños</span> }
                            name="birthday"
                            className={styles.itemColumn}
                            rules={[{required: true, message: 'Ingresar cumpleaños'}]}
                        >
                            <DatePicker size="large" className={styles.input} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={ <span className={styles.label}>DOCUMENTO IDENTIDAD</span> }
                            name="identity"
                            className={styles.itemColumn}
                            rules={[{required: true, message: 'Ingresar DUI'}]}
                        >
                            <Input size="large" className={styles.input} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={ <span className={styles.label}>NOTAS ADICIONALES</span> }
                            name="notes"
                            className={styles.itemColumn}
                            rules={[{message: 'Ingresar notas'}]}
                        >
                            <TextArea size="large" className={styles.input} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className={styles.itemSubmit}>
                        <Button loading={sending} className={styles.submit} size="large" type="primary" htmlType="submit">
                            GUARDAR INFORMACIÓN
                        </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ClientEditor;