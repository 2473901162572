import { useEffect, useState } from 'react';
import {Row, Col, DatePicker, Button, Spin } from 'antd';
import styles from '../styles/Statistics.module.css';
import Chart from 'react-apexcharts';
import { getKPI_OrdersAndSales, getLocations } from '../../../api/kpi';
import LocationSelector from '../../../components/LocationSelector';

const OrdersAndSales = () => {

    //Declarations
    const initialObjQuery = {type:"number", val:-7, location: []};
    const Filtros = [
      {Id:1, Value:-7, Text:"Semana actual", Type:"number"}, 
      {Id:2, Value:-30, Text:"Últimos 30 días", Type:"number"},
      {Id:3, Value:-90, Text:"Últimos trimestre", Type:"number"}
    ];

    const { RangePicker } = DatePicker;

    //useState
    const [datosSemanaA, setddatosSemanaA] = useState([]);
    const [datosSemanaB, setddatosSemanaB] = useState([]);
    const [defaultFiltro, setdefaultFiltro] = useState(1);
    const [loading, handleLoading] = useState(false);
    const [locations, setlocation] = useState([]);
    const [locationValues, setLocationValues] = useState([]);
    const [objQuery, setobjQuery] = useState(initialObjQuery);
    const [defaultDate, setdefaultDate] = useState(null);
    const [defaultRange, setdefaultRange] = useState(null);
    const [statusSelect, setstatusSelect] = useState(true);

    //useEffect
    useEffect(() => {
      getKPI_OrdersAndSales(objQuery).then(response => {
          if(response && response.status === "success"){
              if(response.pedidos){
                setddatosSemanaA(response.pedidos);
                setddatosSemanaB(response.pedidos);
              }
          } else {
              alert("No se procesó la información.");
          }
          handleLoading(false);
      })

      getLocations().then(response => {
        setlocation(response.data);
      })
    }, [objQuery])

    /*
    //Reset data
    const resetDataByLocations = (event) => {
     
      
    }
    */

    const GetMonths = (dateString) => {
      setdefaultDate(dateString);
      setobjQuery({ ...objQuery, type: "date", val: dateString });
      handleLoading(true);

      setdefaultFiltro('');
    };

    const GetRanges = (dateString) => {
      setdefaultRange(dateString);
      if(!(defaultDate === null)){
        setdefaultDate(null);
      }
      if(dateString === null){
        setstatusSelect(true);
      }else{
        setstatusSelect(false);
      }
    }

    const updateLocationData = (payload) => {
        setLocationValues(payload);
        setobjQuery({...objQuery, location: payload});
        handleLoading(true);
        //ResetObjByYears(payload, "year");
    }

    const resetObj = (event) => {
      //console.log(event);
      let objQuery = {type:"number", val:"-7", location: locationValues};
      let ValorFiltro = Number(event.target.parentElement.getAttribute('data-key'));

      if(ValorFiltro === 2){
          objQuery = {type:"number", val:"-30", location: locationValues};
      }
      else if(ValorFiltro === 3){
          objQuery = {type:"number", val:"-90", location: locationValues};
      }
      setdefaultDate(null);
      setdefaultFiltro(ValorFiltro);
      setobjQuery(objQuery);
      handleLoading(true);
    }

    const chartAT = {
        series: [{
            name: "Semana actual",
            data: datosSemanaA.map(x => x.valor_actual)
          },
          {
            name: "Semana anterior",
            data: datosSemanaB.map(x => x.valor_anterior)
          }
        ],
        options:{
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                  enabled: false
                },
            },
            colors: ['#8FD400', '#808080'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [2, 2, 2],
                curve: 'straight',
                dashArray: [0, 8, 5]
            },
            markers: {
                size: 0,
                hover: {
                  sizeOffset: 6
                }
            },
            xaxis: {
                categories: datosSemanaA.map(x=>x.semana_actual)
            },
            tooltip: {
                y: [
                  {
                    title: {
                      formatter: function (val) {
                        return val
                      }
                    }
                  },
                  {
                    title: {
                      formatter: function (val) {
                        return val
                      }
                    }
                  },
                  {
                    title: {
                      formatter: function (val) {
                        return val;
                      }
                    }
                  }
                ]
              },
              grid: {
                borderColor: '#f1f1f1',
              }
        }
    }
    
    return(
        <div>
            <div className={styles.titulo_reportes}>pedidos y ventas</div>
            <div className={styles.mb_10}>
                <Row className={styles.contenedor_filtros+" "+styles.margin_botton_10}>
                    <Col span={14}>
                        {Filtros.map(flt => {
                            const FltSeleccionado = flt.Id === Number(defaultFiltro) ? styles.filtro_seleccionado : styles.filtro_general;
                            return(
                                <Button key={flt.Id} data-key={flt.Id} data-values={flt.Value} data-type={flt.Type} className={FltSeleccionado} onClick={resetObj} >{flt.Text}</Button>
                            )
                        })}
                    </Col>
                    <Col span={10}>
                        <div className={styles.d_Flex}>
                            <DatePicker 
                              onChange={GetMonths} 
                              value={defaultDate}
                              picker="month" 
                              className={styles.mr_2} 
                            />
                            <RangePicker 
                              value={defaultRange}
                              onChange={GetRanges}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className={styles.margin_top_10+" "+styles.margin_botton_10}>
                    <Col span={8} >
                        <LocationSelector dataSource={locations} selectedValues={locationValues} setSelectedValues={updateLocationData} disabled={statusSelect} />
                    </Col>
                </Row>
            </div>
            {
              loading ? (
                <div className={styles.loader}><Spin size="large" /></div>
              ) : (
                <div className={styles.card}>
                  <Row>
                      <div className={styles.font_weight_16+" "+styles.titulo_char_top}>
                          ventas totales
                      </div>
                  </Row>
                  <Row gutter={0}>
                      <Col span={24} >
                          <Chart
                              options={chartAT.options}
                              series={chartAT.series}
                              type="line"
                              width="100%"
                          />
                      </Col>
                  </Row>
              </div>
              )
            }
        </div>
    );
};

export default OrdersAndSales;