import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Modal } from "antd";
import produce from "immer";
import {
  getAllSlides,
  selectSlides,
  deleteSlide,
  activeBanner,
} from "../../redux/slices/frontpage";
import {
  getAllPromos,
  selectPromos,
  activePromoFrontpage,
} from "../../redux/slices/promos";
import { getAllProducts } from "../../redux/slices/products";
import { getAllToppings } from "../../redux/slices/toppings";
import FrontpageUI from "./components/FrontpageUI";

const Frontpage = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [editorPromoVisible, handleEditorPromoVisible] = useState(false);
  const [banner, handleBanner] = useState(null);
  const slides = useSelector(selectSlides);
  const promos = useSelector(selectPromos);
  const [products, setProducts] = useState([]);
  const [toppings, setToppings] = useState([]);
  const [activePromo, setActivePromo] = useState(null);
  const dispatch = useDispatch();

  const initialFetch = useCallback(async () => {
    const [response, response_promos, res_prods, res_toppings] =
      await Promise.all([
        dispatch(getAllSlides()),
        dispatch(getAllPromos()),
        dispatch(getAllProducts(5)),
        dispatch(getAllToppings(5)),
      ]);
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    setActivePromo(response_promos.first);
    setProducts(res_prods.products || []);
    setToppings(res_toppings.toppings || []);
    handleLoading(false);
  }, []);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const editPromo = () => {
    handleEditorPromoVisible(true);
  };

  const editBanner = (newTopping) => {
    handleBanner(newTopping);
    handleEditorVisible(true);
  };

  const closeEditor = () => {
    handleEditorVisible(false);
    handleBanner(null);
  };

  const closeEditorPromo = () => {
    handleEditorPromoVisible(false);
  };

  const newBanner = () => {
    handleBanner(null);
    handleEditorVisible(true);
  };

  const handleActive = async (idBanner, index, val) => {
    const response = await dispatch(activeBanner(idBanner, index, val));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  const confirmDelete = (idSlide) => {
    Modal.confirm({
      title: "¿Estás segúro de eliminar este banner?",
      content: "Esta información no se podrá recuperar.",
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        await removeBanner(idSlide);
      },
      onCancel() {},
    });
  };

  const removeBanner = async (idSlide) => {
    const response = await dispatch(deleteSlide(idSlide));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Banner eliminado correctamente");
    }
  };

  const activatePromo = async () => {
    let status = true;
    let index = -1;
    const newValue = !activePromo.active;
    if (newValue) {
      promos.forEach((promo, promoIndex) => {
        if (promo.active) {
          status = false;
          index = promoIndex;
        }
      });
    }
    if (status) {
      const newActivePromo = produce(activePromo, (draftState) => {
        draftState.active = newValue;
      });
      setActivePromo(newActivePromo);
      const response = await dispatch(
        activePromoFrontpage(activePromo.id, newValue)
      );
      if (response.status !== "success") {
        const oldActivePromo = produce(activePromo, (draftState) => {
          draftState.active = !newValue;
        });
        setActivePromo(oldActivePromo);
        message.error("¡Hubo un problema!");
      } else {
        message.success("Estado cambiado correctamente");
      }
    } else {
      Modal.error({
        title: "Otra promoción activa",
        content: `La promoción ${promos[index].name} esta activa. Es necesario desactivarla para activar esta.`,
      });
    }
  };

  return (
    <FrontpageUI
      loading={loading}
      editorVisible={editorVisible}
      editBanner={editBanner}
      closeEditor={closeEditor}
      newBanner={newBanner}
      banner={banner}
      banners={slides}
      handleBanner={handleBanner}
      confirmDelete={confirmDelete}
      handleActive={handleActive}
      promos={promos}
      activePromo={activePromo}
      setActivePromo={setActivePromo}
      editPromo={editPromo}
      closeEditorPromo={closeEditorPromo}
      editorPromoVisible={editorPromoVisible}
      products={products}
      toppings={toppings}
      activatePromo={activatePromo}
    />
  );
};

export default Frontpage;
