import Header from "../../../components/HeaderList";
import Store from "../../../assets/sections/store.png";
import Blog from "../../../assets/sections/blog.png";
import Web from "../../../assets/sections/web.png";
import CardSection from "../../../components/CardSection";
import styles from "../styles/ContentUI.module.css";

const ContentsUI = () => (
  <div>
    <Header title="Contenidos" />
    <div className={styles.sections}>
      <CardSection image={Store} label="TIENDAS" route="/contenidos/tiendas" />
      <CardSection image={Blog} label="BLOG" route="/contenidos/blogs" />
      <CardSection image={Web} label="PÁGINAS" route="/contenidos/paginas" />
    </div>
  </div>
);

export default ContentsUI;
