import {useRef, useState, useEffect} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Upload,
  Button,
  TimePicker,
  TreeSelect,
  message,
  Modal,
} from 'antd';
import {GoogleMap, useJsApiLoader, StandaloneSearchBox} from '@react-google-maps/api';
import {useDispatch} from 'react-redux';
import cx from 'classnames';
import moment from 'moment';
import {editLocation, newLocation, deleteLocation} from '../../../redux/slices/branches';
import {normFile, beforeUpload, dummyRequest} from '../../../utils/images';
import {ReactComponent as Gps} from '../../../assets/icons/gps.svg';
import Close from '../../../components/Close';
import styles from '../styles/Editor.module.css';

const {TextArea} = Input;
const {TreeNode} = TreeSelect;

const libraries = ['places'];
const format = 'HH:mm'

const containerStyle = {
  width: '100%',
  height: '560px'
};

const center = {
  lat: 13.701319108430834,
  lng: -89.22441469943618,
};

const Editor = ({close, branch, departments, handleBranch}) => {
  const searchbox = useRef(null);
  const [deleting, handleDeleting] = useState(false);
  const [sending, handleSending] = useState(false);
  const [mapLoaded, handleMapLoaded] = useState(false);
  const [preview, handlePreview] = useState(null);
  const [form] = Form.useForm();
  const map = useRef(null);
  const dispatch = useDispatch();
  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCaN5j6rS669eiak_Aml-ryQzmPAtn2O_4',
    libraries,
  });

  useEffect(() => {
    const initialFetch = async () => {
      try {
        if (branch && mapLoaded) {
          const newValues = {
            name: branch.name,
            address: branch.direction,
            locality: `${branch.department}-${branch.localityId}`,
            text_hours: branch.text_hours,
            delivery_time: branch.delivery_time,
          };
          if (branch.hours) {   
            newValues.monday = getHours('Lunes');
            newValues.tuesday = getHours('Martes');
            newValues.wednesday = getHours('Miercoles');
            newValues.thursday = getHours('Jueves');
            newValues.friday = getHours('Viernes');
            newValues.saturday = getHours('Sabado');
            newValues.sunday = getHours('Domingo');
          }
          if (branch.image) {
            handlePreview(branch.image);
          }
          form.setFieldsValue(newValues);
          const coords = branch.coords.split(' ');
          map.current.setCenter({
            lat: parseFloat(coords[1]),
            lng: parseFloat(coords[0]),
          });
        }
      } catch (e) {
        console.log(e)
      }
    };
    initialFetch();
  }, [branch, mapLoaded]);

  const getHours = day => {
    const newHours = JSON.parse(branch.hours);
    const index = newHours.findIndex(el => el.day === day);
    const schedule = newHours[index].hours.split(' - ');
    return [moment(schedule[0], 'hh:mmA'), moment(schedule[1], 'hh:mmA')];
  };

  const onPlacesChanged = () => {
    const location = searchbox.current.getPlaces()[0].geometry.location;
    map.current.setCenter({
      lat: location.lat(),
      lng: location.lng(),
    });
  };

  const getDepartments = () => (
    departments.map(department => (
      <TreeNode
        key={department.id}
        selectable={false}
        value={department.id}
        title={department.label}
      >
        {department.items.map(locality => (
          <TreeNode
            key={`${department.id}-${locality.id}`}
            value={`${department.id}-${locality.id}`}
            title={locality.name}
          />
        ))}
      </TreeNode>
    ))
  );

  const renderMap = () => {
    const onLoadSearchbox = ref => {
      searchbox.current = ref;
      handleMapLoaded(true);
    };

    const onLoadMap = ref => {
      map.current = ref;
    }

    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        onLoad={onLoadMap}
        zoom={16}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          fullscreenControl: false,
        }}
      >
        <StandaloneSearchBox
          onLoad={onLoadSearchbox}
          onPlacesChanged={
            onPlacesChanged
          }
        >
          <input
            type="text"
            placeholder="Buscar..."
            className={styles.searchbox}
          />
        </StandaloneSearchBox>
      </GoogleMap>
    );
  }

  const getHourFormat = (label, values) => {
    return {
      day: label,
      hours: `${values[0].format('hh:mmA')} - ${values[1].format('hh:mmA')}`,
    };
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: '¿Estás seguro de eliminar esta tienda?',
      content: 'No se podrá recuperar esta información.',
      okText: 'Eliminar',
      cancelText: 'Cancelar',
      okType: 'danger',
      onOk() {
        deleteBranch();
      },
      onCancel() {},
    });
  };

  const deleteBranch = async () => {
    handleDeleting(true);
    const response = await dispatch(deleteLocation(branch.id));
    if (response.status === 'success') {
      message.success('Se ha borrado esta tienda');
      close();
    } else {
      message.error('¡Hubo un problema! Inténtalo de nuevo');
    }
    handleDeleting(false);
  }

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      if (preview && preview.length) {
        const newLocality = values.locality.split('-');
        const newCenter = map.current.getCenter().toJSON();
        const newHours = [
          getHourFormat('Lunes', values.monday),
          getHourFormat('Martes', values.tuesday),
          getHourFormat('Miercoles', values.wednesday),
          getHourFormat('Jueves', values.thursday),
          getHourFormat('Viernes', values.friday),
          getHourFormat('Sabado', values.saturday),
          getHourFormat('Domingo', values.sunday),
        ]
        const info = new FormData();
        if (branch) {
          info.append('id', branch.id);
        }
        info.append('name', values.name);
        info.append('delivery_time', values.delivery_time);
        info.append('delivery', false);
        info.append('text_hours', values.text_hours);
        info.append('coords', `${newCenter.lng}, ${newCenter.lat}`);
        info.append('department', newLocality[0]);
        info.append('locality', newLocality[1]);
        info.append('direction', values.address);
        info.append('hours', JSON.stringify(newHours));
        info.append('image', values.image?.length ? values.image[0]?.originFileObj : preview);
        if (branch) {
          const response = await dispatch(editLocation(branch.id, info));
          if (response.status === 'success') {
            message.success('Cambios realizados con éxito');
          } else {
            message.error('¡Hubo un problema! Inténtalo de nuevo');
          }
        } else {
          const response = await dispatch(newLocation(info));
          if (response.status === 'success') {
            message.success('Cambios realizados con éxito');
            handleBranch(response.branch);
          } else {
            message.error('¡Hubo un problema! Inténtalo de nuevo');
          }
        }
      } else {
        message.error('Seleciona una imagen válida');
      }

      handleSending(false);
    } catch (e) {
      console.log(e)
    }
  };
  
  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>
          {branch ? 'Editar tienda' : 'Nueva tienda'}
        </span>
        <Close action={close} />
      </div>
      <Form
        name="branch-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
      >
        <Row gutter={[40, 40]}>
          <Col span={15}>
            <Row>
              <Col span={24}>
                <Form.Item
                label={
                  <span className={styles.label}>
                    NOMBRE
                  </span>
                }
                name="name"
                className={styles.itemColumn}
                rules={[{required: true, message: 'Ingresar nombre'}]}
              >
                <Input size="large" className={styles.input} />
              </Form.Item>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      className={styles.itemColumn}
                      label={
                        <span className={styles.label}>
                          MUNICIPIO
                        </span>
                      }
                      rules={[{required: true, message: 'Ingresar municipio'}]}
                      name="locality">
                      <TreeSelect
                        showSearch
                        className={styles.input}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder="Seleccionar municipio"
                        treeDefaultExpandAll
                        size="large"
                        filterTreeNode={(search, item) => {
                          return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                        }}
                      >
                        {getDepartments()}
                      </TreeSelect>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      TIEMPO DE ENTREGA
                    </span>
                  }
                  name="delivery_time"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar tiempo'}]}
                >
                  <Input
                    size="large"
                    className={styles.input}
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className={styles.mapWrapper}>
                  {isLoaded && renderMap()}
                  {isLoaded && <Gps className={styles.gps} />}
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={9}>
            <Form.Item
              name="image"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              label={
                <span className={styles.label}>
                  IMAGEN BASE
                </span>
              }
              className={styles.itemColumn}
              extra={<span className={styles.center}>(Formatos jpg o png de 1000px ancho)</span>}
            >
              <Upload
                maxCount={1}
                showUploadList={false}
                customRequest={e => dummyRequest(e, handlePreview)}
                beforeUpload={beforeUpload}
                listType="picture">
                <Button size="large" className={styles.uploadImage}>Cambiar imagen</Button>
              </Upload>
            </Form.Item>
            <div className={styles.preview} style={{backgroundImage: `url(${preview})`}} />
            <Form.Item
              label={
                <span className={styles.label}>
                  DIRECCIÓN
                </span>
              }
              name="address"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar dirección'}]}
            >
              <TextArea rows={4} className={styles.input} />
            </Form.Item>
            <Form.Item
              label={
                <span className={styles.label}>
                  HORARIO A MOSTRAR
                </span>
              }
              name="text_hours"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar horario a mostrar'}]}
            >
              <TextArea rows={4} className={styles.input} />
            </Form.Item>
            <div className={styles.hours}>
              <span className={cx(styles.label, styles.labelSection)}>
                HORARIOS
              </span>
              <div className={styles.hoursList}>
                <Form.Item
                  label={
                    <span className={cx(styles.label, styles.labelHour)}>
                      LUNES
                    </span>
                  }
                  name="monday"
                  rules={[{required: true, message: 'Ingresar horario'}]}
                >
                  <TimePicker.RangePicker format={format} className={styles.input} placeholder={['Apertura', 'Cierre']} />
                </Form.Item>
                <Form.Item
                  label={
                    <span className={cx(styles.label, styles.labelHour)}>
                      MARTES
                    </span>
                  }
                  name="tuesday"
                  rules={[{required: true, message: 'Ingresar horario'}]}
                >
                  <TimePicker.RangePicker format={format} className={styles.input} placeholder={['Apertura', 'Cierre']} />
                </Form.Item>
                <Form.Item
                  label={
                    <span className={cx(styles.label, styles.labelHour)}>
                      MÍERCOLES
                    </span>
                  }
                  name="wednesday"
                  rules={[{required: true, message: 'Ingresar horario'}]}
                >
                  <TimePicker.RangePicker format={format} className={styles.input} placeholder={['Apertura', 'Cierre']} />
                </Form.Item>
                <Form.Item
                  label={
                    <span className={cx(styles.label, styles.labelHour)}>
                      JUEVES
                    </span>
                  }
                  name="thursday"
                  rules={[{required: true, message: 'Ingresar horario'}]}
                >
                  <TimePicker.RangePicker format={format} className={styles.input} placeholder={['Apertura', 'Cierre']} />
                </Form.Item>
                <Form.Item
                  label={
                    <span className={cx(styles.label, styles.labelHour)}>
                      VIERNES
                    </span>
                  }
                  name="friday"
                  rules={[{required: true, message: 'Ingresar horario'}]}
                >
                  <TimePicker.RangePicker format={format} className={styles.input} placeholder={['Apertura', 'Cierre']} />
                </Form.Item>
                <Form.Item
                  label={
                    <span className={cx(styles.label, styles.labelHour)}>
                      SÁBADO
                    </span>
                  }
                  name="saturday"
                  rules={[{required: true, message: 'Ingresar horario'}]}
                >
                  <TimePicker.RangePicker format={format} className={styles.input} placeholder={['Apertura', 'Cierre']} />
                </Form.Item>
                <Form.Item
                  label={
                    <span className={cx(styles.label, styles.labelHour)}>
                      DOMINGO
                    </span>
                  }
                  name="sunday"
                  rules={[{required: true, message: 'Ingresar horario'}]}
                >
                  <TimePicker.RangePicker format={format} className={styles.input} placeholder={['Apertura', 'Cierre']} />
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.itemSubmit}>
              {branch && (
                <Button
                  loading={deleting}
                  className={cx(styles.submit, styles.delete)}
                  onClick={confirmDelete}
                  size="large"
                  type="danger">
                  ELIMINAR
                </Button>
              )}
              <Button
                loading={sending}
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit">
                GUARDAR TIENDA
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Editor;
