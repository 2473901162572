import { useEffect, useState } from 'react';
import {Row, Col, DatePicker, Table, Button  } from 'antd';
import styles from '../styles/Statistics.module.css';
import { getKPITopProducts, getLocations } from '../../../api/kpi';
import LocationSelector from '../../../components/LocationSelector';

const TopProducts = () => 
{
    const initialObj = {type:"number", val:"0", location: []}; 
    const Filtros = [
        {Id:1, Value:"0", Text:"Hoy", Type:"number"}, 
        {Id:2, Value:"-1", Text:"Ayer", Type:"number"},
        {Id:3, Value:"-7", Text:"Últimos 7 días", Type:"number"},
        {Id:4, Value:"-30", Text:"Últimos 30 días", Type:"number"}
    ];
    const Btn_Options = [
        {Id: 1, Text: "Productos", Value: "productos"},
        {Id: 2, Text: "Toppings", Value: "toppings"}
    ];

    const { RangePicker } = DatePicker;

    const [loading, handleLoading] = useState(false);
    const [datosTop, setdatosTop] = useState([]);
    const [obj, setObj] = useState(initialObj);
    const [defaultDate, setDefaultDate] = useState('');
    const [defaultRange, setDefaultRange] = useState('');
    const [defaultFiltro, setDefaultFiltro] = useState(1);
    const [locations, setLocations] = useState([]);
    const [defaultOption, setdefaultOption] = useState("productos");
    const [locationValues, setLocationValues] = useState([]);
    //const [defaultLocation, setdefaultLocation] = useState(0);

    useEffect(() => {
        getKPITopProducts(obj).then(response => {
            if(response && response.status === "success"){
                if(defaultOption === "productos"){
                    setdatosTop(response.productos);

                } else if(defaultOption === "toppings"){
                    setdatosTop(response.toppings);
                }
            } else {
                alert("No se procesó la información 1.");
            }
            handleLoading(false);
        });

        getLocations().then(response => {
            setLocations(response.data);
        });

    }, [obj, defaultFiltro, defaultOption] )


    const GetMonths = (dateString) => {
        let fecha = dateString.format("YYYY-MM");
        ResetObjByDates(fecha, "date");
        setDefaultDate(dateString);
        setDefaultFiltro(0);

        if(!(defaultRange === '')){
            setDefaultRange('');
        }
    };

    const GetRanges = (dateString) =>{
        let date1 = dateString[0].format('DD-MM-YYYY');
        let date2 = dateString[1].format('DD-MM-YYYY');
        let ranges = [date1, date2];
        ResetObjByDates(ranges, "range");
        setDefaultRange(dateString);
        setDefaultFiltro(0);

        if(!(defaultDate === '')){
            setDefaultDate('');
        }
    }

    /*
    const GetLocalidades = (id) => {
        let Localidad_Id = id;
        let Type = "";

        if(!(defaultDate === "")){
            Type = "date";

        }else if(!(defaultRange === "")){
            Type = "range";

        }else if(!(defaultFiltro === "")){
            Type = "filtro";
        };

        setdefaultLocation(id);
        ResetObjByLocations(Localidad_Id, Type);
    }
    */

    
    const updateLocationData = (payload) => {
        //console.log(payload);
        let Type = "";
        if(!(defaultDate === "")){
            Type = "date";
        }
        else if(!(defaultRange === "")){
            Type = "range";
        }
        else if(!(defaultFiltro === 0)){
            Type = "filtro";
        };
       
        setLocationValues(payload);
        ResetObjByLocations(payload, Type);
        handleLoading(true);
    }

    const ResetObj = (event) =>
    {
        let Type = '';
        let objQuery = {};
        const opcionSel = event.target.parentElement.getAttribute('data-area');
        const opc = event.target.parentElement.getAttribute('data-option');
        const opd = event.target.parentElement.getAttribute('data-key');

        if(opcionSel === "product") {
            setdefaultOption(opc);
        } 
        if(opcionSel === "date") {
            setDefaultFiltro(Number(opd));
        }

        if(!(defaultDate === "")){
            Type = "date";
        }
        else if(!(defaultRange === "")){
            Type = "range";
        }
        else if(!(defaultFiltro === "")){
            Type = "filtro";
        };

        if(Type === "date"){
            let fecha = defaultDate.format("YYYY-MM");
            objQuery = {type:"date", val:fecha, location: locationValues};
        }
        else if(Type === "range"){
            let date1 = defaultRange[0].format('DD-MM-YYYY');
            let date2 = defaultRange[1].format('DD-MM-YYYY');
            let ranges = [date1, date2];
            objQuery = {type:"range", val:ranges, location: locationValues};

        }
        else if(Type === "filtro"){
            let IdFiltroGeneral = defaultFiltro - 1;
            let ValorFiltroGeneral = Filtros[IdFiltroGeneral].Value;
            objQuery = {type:"number", val:ValorFiltroGeneral, location: locationValues};
        }   

        setObj(objQuery);
        handleLoading(true);

        //getKPITopProducts(objQuery).then(response => {
        //    if(response.status === "success"){
        //        if(defaultOption === "productos"){
        //            setdatosTop(response.productos);
        //        }else if(defaultOption === "toppings"){
        //            setdatosTop(response.toppings);
        //        }
        //    }else{
        //        alert("No se procesó la información.");
        //    }
        //    handleLoading(false);
        //})
    }

    const ResetObjByDates = (date, type) => 
    {   
        setObj({type: type, val: date, location: []});
        handleLoading(true);

        //getKPITopProducts(obj).then(response => {
        //    if(response.status === "success"){
        //        setdatosTop(response.productos);
        //    }else{
        //        alert("No se procesó la información.");
        //    }
        //    handleLoading(false);
        //})
    }

    const ResetObjByLocations = (id_location, type) => 
    {    
        let obj_query = {};
        if(type === "date"){
            obj_query = {type:"date", val:defaultDate.format("YYYY-MM"), location: id_location};
            
        }
        else if(type === "range"){
            let date1 = defaultRange[0].format('DD-MM-YYYY');
            let date2 = defaultRange[1].format('DD-MM-YYYY');
            let ranges = [date1, date2];
            obj_query = {type:"range", val:ranges, location: id_location};
        }
        else if(type === "filtro") {
            if(defaultFiltro === 1) {
                obj_query = {type:"number", val:"0", location: id_location};
            } 
            else if(defaultFiltro === 2){
                obj_query = {type:"number", val:"-1", location: id_location};
            }
            else if(defaultFiltro === 3){
                obj_query = {type:"number", val:"-7", location: id_location};
            }
            else if(defaultFiltro === 4){
                obj_query = {type:"number", val:"-30", location: id_location};
            }  
        }

        setObj(obj_query);
        handleLoading(true);

        //getKPITopProducts(obj_query).then(response => {
        //    if(response.status === "success"){
        //        if(defaultOption === "productos"){
        //            setdatosTop(response.productos);
        //        }else if(defaultOption === "toppings"){
        //            setdatosTop(response.toppings);
        //        }
        //    }else{
        //        alert("No se procesó la información.");
        //    }
        //    handleLoading(false);
        //})
    }

    const columns=[
        {
            title: 'Posición',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Producto',
            dataIndex: 'nombre',
            key: 'nombre'
        },
        {
            title: 'Cantidad',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'center',
        }
    ]

    return (
        <div>
            <div className={styles.titulo_reportes}>top productos</div>
            <div className={styles.mb_10}>
                <Row className={styles.contenedor_filtros+" "+styles.margin_botton_10}>
                    <Col span={12}>
                        {Filtros.map(flt => {
                            const FltSeleccionado = flt.Id === Number(defaultFiltro) ? styles.filtro_seleccionado : styles.filtro_general;
                            return(
                                <Button key={flt.Id} data-area="date" data-key={flt.Id} data-values={flt.Value} data-type={flt.Type} className={FltSeleccionado} onClick={ResetObj} >{flt.Text}</Button>
                            )
                        })}
                    </Col>
                    <Col span={12}>
                        <div className={styles.d_Flex}>
                            <DatePicker
                              onChange={GetMonths} 
                              value={defaultDate}
                              picker="month" 
                              className={styles.mr_2} 
                            />
                            <RangePicker
                             onChange={GetRanges}
                             value={defaultRange}
                             />
                        </div>
                    </Col>
                </Row>
                <Row className={styles.margin_top_10+" "+styles.margin_botton_10}>
                    <Col span={10}>
                        <LocationSelector dataSource={locations} selectedValues={locationValues} setSelectedValues={updateLocationData} />
                    </Col>
                    <Col span={14}>
                        {Btn_Options.map(opt => {
                            const optSeleccionada = opt.Value === defaultOption ? styles.active : styles.no_active;
                            return(
                                <Button 
                                key={opt.Id} 
                                data-area="product"
                                data-option={opt.Value} 
                                onClick={ResetObj} 
                                className={styles.margin_left_10+" "+optSeleccionada+" "+styles.font_weight_14}   
                                >
                                    {opt.Text}
                                </Button>
                            )
                        })}
                    </Col>
                </Row>
            </div>
            <div className={styles.view}>
                <div>
                    <Table loading={loading} dataSource={datosTop} columns={columns}/>
                </div>
            </div>
        </div>
    )
};

export default TopProducts;