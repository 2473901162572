import {Button, Table, Spin, Modal} from 'antd';
import moment from 'moment';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import {getColumnSearchProps} from '../../../utils/tables';
import Editor from './Editor';
import styles from '../styles/BlogsUI.module.css';

const BlogsUI = props => {
  const {
    loading,
    blogs,
    blog,
    categories,
    openEditor,
    editorVisible,
    closeEditor,
    newBlog,
    confirmDelete,
    handleBlog,
  } = props;

  const getCategoryName = category => {
    let name = '-';
    const index = categories.findIndex(el => el.id === category);
    console.log(index);
    if (index > -1) {
      name = categories[index].name;
    }
    return name;
  };


  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Título',
      dataIndex: 'title',
      align: 'center',
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Categoría',
      dataIndex: 'categoryId',
      align: 'center',
      render: val => getCategoryName(val),
    },
    {
      title: 'Fecha',
      dataIndex: 'date_created',
      align: 'center',
      render: val => moment(val).format('DD/MM/YY'),
    },
    {
      title: 'Editar',
      align: 'center',
      dataIndex: 'id',
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => openEditor(record)}>
            <Edit />
          </div>
          <div className={styles.divider} />
          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.view}>
      <Header
        back="/contenidos"
        title="Blog"
        actions={
          <Button
            onClick={newBlog}
            type="primary"
            size="large"
            className="main-button">
            AGREGAR ENTRADA
          </Button>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={blogs}
            rowKey="id"
          />
        </div>
      )}
      <Modal
        destroyOnClose
        closable={false}
        visible={editorVisible}
        footer={false}
        onCancel={closeEditor}
        width={1200}
      >
        <Editor
          close={closeEditor}
          blog={blog}
          blogs={blogs}
          handleBlog={handleBlog}
          categories={categories}
        />
      </Modal>
    </div>
  );
};

export default BlogsUI;
