import { useEffect, useState } from 'react';
import { Row, Col, Spin, DatePicker, Button } from 'antd';
import styles from '../styles/Statistics.module.css';
import { getKPIResumen } from '../../../api/kpi';


const Resumen = () => 
{
    const initialObj = {type:"number", val:"0", location: []}; 
    const Filtros = [
        {Id:1, Value:"0", Text:"Hoy", Type:"number"}, 
        {Id:2, Value:"-1", Text:"Ayer", Type:"number"},
        {Id:3, Value:"-7", Text:"Últimos 7 días", Type:"number"},
        {Id:4, Value:"-30", Text:"Últimos 30 días", Type:"number"}
    ];
    const { RangePicker } = DatePicker;
    const [loading, handleLoading] = useState(false);
    const [resumen, setResumen] = useState([]);
    const [obj, setObj] = useState(initialObj);
    const [defaultDate, setDefaultDate] = useState('');
    const [defaultRange, setDefaultRange] = useState('');
    const [defaultFiltro, setDefaultFiltro] = useState(1);

    useEffect(() => {
        getKPIResumen(obj).then(response => {
            if(response.status === "success"){
                setResumen(response.resumen);
            } else {
                alert("No se procesó la información 1.");
            }
            handleLoading(false);
        })
    }, [obj, defaultFiltro, handleLoading] )

    const GetMonths = (dateString) => {
        let fecha = dateString.format("YYYY-MM");

        ResetObjByDates(fecha, "date");
        setDefaultDate(dateString);
        setDefaultFiltro(0);

        if(!(defaultRange === '')){
            setDefaultRange('');
        }
    };

    const GetRanges = (dateString) =>{
        let date1 = dateString[0].format('DD-MM-YYYY');
        let date2 = dateString[1].format('DD-MM-YYYY');
        let ranges = [date1, date2];
        ResetObjByDates(ranges, "range");
        setDefaultRange(dateString);
        setDefaultFiltro(0);

        if(!(defaultDate === '')){
            setDefaultDate('');
        }
    }

    const ResetObj = (event) => 
    {
        let ValorFiltro = Number(event.target.parentElement.getAttribute('data-key'));
        let objQuery = {};
        switch(ValorFiltro){
            case 1:
                objQuery = {type:"number", val:"0", location: []};
                break;
            case 2:
                objQuery = {type:"number", val:"-1", location: []};
                break;
            case 3:
                objQuery = {type:"number", val:"-7", location: []};
                break;
            case 4:
                objQuery = {type:"number", val:"-30", location: []};
                break;
            default:
                objQuery = {type:"number", val:"0", location: []};
                break;
        }
        setObj(objQuery);
        //getKPIResumen(obj).then(response => {
        //    if(response.status === "success"){
        //        setResumen(response.resumen);
        //    }else{
        //        alert("No se procesó la información.");
        //    }
        //    handleLoading(false);
        //})
        setDefaultDate('');
        setDefaultRange('');
        setDefaultFiltro(ValorFiltro);
        handleLoading(true);
    }

    const ResetObjByDates = (date, type) => 
    {
        setObj({type: type, val: date, location: []});
        handleLoading(true);
        //getKPIResumen(obj).then(response => {
        //    if(response.status === "success"){
        //        setResumen(response.resumen);
        //    }else{
        //        alert("No se procesó la información.");
        //    }
        //    handleLoading(false);
        //})
    }

    return (
        <div>
            <div className={styles.titulo_reportes}>KPI's</div>
            <div className={styles.mb_10}>
                <Row className={styles.contenedor_filtros}>
                    <Col span={12}>
                        {Filtros.map(flt => {
                            const FltSeleccionado = flt.Id === Number(defaultFiltro) ? styles.filtro_seleccionado : styles.filtro_general;
                            return(
                                <Button key={flt.Id} data-key={flt.Id} data-values={flt.Value} data-type={flt.Type} className={FltSeleccionado} onClick={ResetObj} >{flt.Text}</Button>
                            )
                        })}
                    </Col>
                    <Col span={12}>
                        <div className={styles.d_Flex}>
                            <DatePicker
                              onChange={GetMonths} 
                              value={defaultDate}
                              picker="month" 
                              className={styles.mr_2} 
                            />
                            <RangePicker
                             onChange={GetRanges}
                             value={defaultRange}
                             />
                        </div>
                    </Col>
                </Row>
            </div>
            {
                loading ? (
                    <div className={styles.loader}>
                    <Spin size="large" />
                    </div>
                ) : (
                    <div className={styles.card}>
                        <Row gutter={0}>
                            <Col span={24}>
                                <div className={styles.cuadrante}>
                                    <div className={styles.unoseis}>
                                        <div className={styles.boxdash_container}>
                                            <div className={styles.indicador}>{resumen.ordenes_entregadas}</div>
                                            <div className={styles.contenedor_desc}>
                                                <div className={styles.desc_indicador}>TOTAL DE ORDENES ENTREGADAS</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.dosseis}>
                                        <div className={styles.boxdash_container}>
                                            <div className={styles.indicador}>{resumen.ordenes_canceladas}</div>
                                            <div className={styles.contenedor_desc}>
                                                <p className={styles.desc_indicador}>TOTAL DE ORDENES CANCELADAS</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.tresseis}>
                                        <div className={styles.boxdash_container}>
                                            <div className={styles.indicador}>{resumen.promedio_canceladas}</div>
                                            <div className={styles.contenedor_desc}>
                                                <p className={styles.desc_indicador}>TIEMPO PROMEDIO TOTAL DE ENTREGA</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.cuatroseis}>
                                        <div className={styles.boxdash_container}>
                                            <div className={styles.indicador}>{resumen.promedio_entrega}</div>
                                            <div className={styles.contenedor_desc}>
                                                <p className={styles.desc_indicador}>TIEMPO PROMEDIO DE DESPACHO</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.cincoseis}>
                                        <div className={styles.boxdash_container}>
                                            <div className={styles.d_Flex+" "+styles.aling_items_baseline}>
                                                <div className={styles.font_size_36}>
                                                    $
                                                </div>
                                                <div className={styles.indicador}>{resumen.promedio_ticket}</div>
                                            </div>
                                            <div className={styles.contenedor_desc}>
                                                <p className={styles.desc_indicador}>PROMEDIO DE TICKET DIARIO</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.seisseis}>
                                        <div className={styles.boxdash_container}>
                                            <div className={styles.indicador}>{resumen.promedio_ordenes}</div>
                                            <div className={styles.contenedor_desc}>
                                                <p className={styles.desc_indicador}>PROMEDIO DE ORDENES DIARIAS</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )
            }
        </div>
    )  
};

export default Resumen;