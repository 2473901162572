import { useEffect, useState } from 'react';
import {Row, Col, DatePicker, Spin } from 'antd';
import styles from '../styles/Statistics.module.css';
import Chart from 'react-apexcharts';
import { getKPI_HourlyOrders, getLocations } from '../../../api/kpi';
import LocationSelector from '../../../components/LocationSelector';

const HourlyOrders = () => {

    const initialObj = {type:"number", val:"0", location: []};

    const { RangePicker } = DatePicker;

    //useState
    const [obj, setObj] = useState(initialObj);
    //const [datos2, setDatos2] = useState([]);
    const [datos, setDatos] = useState([]);
    const [loading, handleLoading] = useState(false);
    const [locations, setLocation] = useState([]);
    const [locationValues, setLocationValues] = useState([]);
    const [defaultRange, setDefaultRange] = useState(null);
    const [statusSelect, setstatusSelect] = useState(true);
    //const [locationSelect, setlocationSelect] = useState('');

    //useEffect
    useEffect(() => {
        getKPI_HourlyOrders(obj).then(response => {
            if(response.status === "success"){
                if(response.pedidos){
                    setDatos(response.pedidos);
                }
                //if(response.ventas){
                //    setDatos2(response.ventas);
                //}
            }else{
                alert("No se procesó la información.");
            }
            handleLoading(false);
        })

        getLocations().then(response => {
            setLocation(response.data);
        })
    }, [obj])

    const GetRanges = (dateString) =>{
        setDefaultRange(dateString);
        //if(!(dateString === null)){
            let date1 = dateString[0].format('DD-MM-YYYY');
            let date2 = dateString[1].format('DD-MM-YYYY');
            let ranges = [date1, date2];
            let new_objQuery = {type:"range", val:ranges, location: locationValues};
            
            setstatusSelect(false);
            setObj(new_objQuery);
            handleLoading(true);
            //getKPI_HourlyOrders(obj).then(response => {
            //    if(response.status === "success"){
            //        if(response.pedidos){
            //            setDatos(response.pedidos);
            //        }
            //    }else{
            //        alert("No se procesó la información.");
            //    }
            //})
        //}else{
        //    setstatusSelect(true);
        //}
    }

    /*
    const GetLocalidades = (id) => {
        let date1 = defaultRange[0].format('DD-MM-YYYY');
        let date2 = defaultRange[1].format('DD-MM-YYYY');
        let ranges = [date1, date2];
        let objQuery = {type: 'range', val: ranges, location: id};
        setlocationSelect(id);
        setObj(objQuery);
        handleLoading(true);
        //getKPI_HourlyOrders(obj).then(response => {
        //    if(response.status === "success"){
        //        if(response.pedidos){
        //           setDatos(response.pedidos);
        //        }
        //    }else{
        //        alert("No se procesó la información.");
        //    }
        //})
    }
    */

    const updateLocationData = (payload) => {
        setLocationValues(payload);
        setObj({...obj, location: payload});
        handleLoading(true);
        //ResetObjByYears(payload, "year");
    }

    const chartAT = {
        series: [{
            name: "Ordenes",
            data: datos.map(x => x.valor)
        }],
        options:{
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                  enabled: false
                }
            },
            colors: ['#8FD400'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: datos.map(x => x.mes)
            }
        }
    }
    
    return(
        <div>
            <div className={styles.titulo_reportes}>ordenes por hora</div>
            <div className={styles.mb_10}>
                <Row className={styles.margin_top_10+" "+styles.margin_botton_10+" "+styles.justify_content_end}>
                    <Col span={17} >
                        <LocationSelector dataSource={locations} selectedValues={locationValues} setSelectedValues={updateLocationData} disabled={statusSelect} />
                    </Col>
                    <Col span={7}>
                        <RangePicker
                            onChange={GetRanges}
                            value={defaultRange} 
                        />
                    </Col>
                </Row>
            </div>
            {
                loading ? (
                    <div className={styles.loader}><Spin size="large" /></div>
                ) : (
                    <div className={styles.card}>
                        <Row>
                            <div className={styles.font_weight_16+" "+styles.titulo_char_top}>
                                Pedidos por horas
                            </div>
                        </Row>
                        <Row gutter={0}>
                            <Col span={24} >
                                <Chart
                                    options={chartAT.options}
                                    series={chartAT.series}
                                    type="line"
                                    width="100%"
                                />
                            </Col>
                        </Row>
                    </div>
                )
            }
        </div>
    ); 
};

export default HourlyOrders;