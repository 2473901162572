import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import moment from "moment";
import {
  getAllBranches,
  selectBranches,
  getAllDepartments,
  deliveryLocation,
  getDeliverySchedule,
  updateDeliverySchedule,
} from "../../redux/slices/branches";
import BranchesUI from "./components/BranchesUI";

const Branches = () => {
  const [loading, handleLoading] = useState(true);
  const [branch, handleBranch] = useState(null);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [scheduleVisible, setScheduleVisible] = useState(false);
  const [departments, handleDepartments] = useState([]);
  const [times, setTimes] = useState([moment(), moment()]);
  const branches = useSelector(selectBranches);
  const dispatch = useDispatch();

  const getTime = (time) => {
    return moment(`${time.substring(0, 2)}:${time.substring(2, 4)}`, "HH:mm");
  };

  const initialFetch = useCallback(async () => {
    const [response, response_departments, response_schedule] =
      await Promise.all([
        dispatch(getAllBranches()),
        dispatch(getAllDepartments()),
        dispatch(getDeliverySchedule()),
      ]);
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      handleDepartments(response_departments.departments);
      const { horario_apertura, horario_cierre } = response_schedule.schedule;
      setTimes([
        getTime(horario_apertura.value),
        getTime(horario_cierre.value),
      ]);
    }
    handleLoading(false);
  }, [dispatch]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const openEditor = (branchToEdit) => {
    handleBranch(branchToEdit);
    handleEditorVisible(true);
  };

  const newBranch = () => {
    handleBranch(null);
    handleEditorVisible(true);
  };

  const closeEditor = () => {
    handleBranch(null);
    handleEditorVisible(false);
  };

  const handleDelivery = async (idBranch, val) => {
    const response = await dispatch(deliveryLocation(idBranch, val));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  const updateSchedule = async () => {
    const response = await dispatch(
      updateDeliverySchedule(times[0].format("HHmm"), times[1].format("HHmm"))
    );
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Horario editado correctamente");
    }
  };

  return (
    <BranchesUI
      loading={loading}
      branches={branches}
      branch={branch}
      openEditor={openEditor}
      editorVisible={editorVisible}
      closeEditor={closeEditor}
      departments={departments}
      initialFetch={initialFetch}
      newBranch={newBranch}
      handleDelivery={handleDelivery}
      handleBranch={handleBranch}
      scheduleVisible={scheduleVisible}
      setScheduleVisible={setScheduleVisible}
      times={times}
      setTimes={setTimes}
      updateSchedule={updateSchedule}
    />
  );
};

export default Branches;
