import instance from './Request';

export const getClients = async () => {
  const request = await instance();
  let data = await request
    .get('/clients')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changeActive = async client => {
  const request = await instance();
  let data = await request
    .post('/clients/block', {customer: client})
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getClient = async user => {
  const request = await instance();
  let data = await request
    .get(`/clients/${user}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const createClient = async (cli) => {
  const request = await instance();
  let data = await request.post("/register", cli).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const updateClient = async (id, cli) => {
  const request = await instance();
  let data = await request.post("/clients/update", cli).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getZones = async depId => {
  const request = await instance();
  let data = await request
    .get(`/zone/department/${depId}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const createAddress = async (addr) => {
  const request = await instance();
  let data = await request.post("/clients/new-address", addr).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const updateAddress = async (id, addr) => {
  const request = await instance();
  let data = await request.post("/clients/save-address", addr).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const removeAddress = async (obj) => {
  const request = await instance();
  let data = await request.post("/clients/delete-address", obj).catch((error) => {
    return {
      error,
    };
  });
  return data;
};
