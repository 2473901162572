import {createSlice} from '@reduxjs/toolkit';
import {getClients, getClient, changeActive, updateClient, createClient, updateAddress, createAddress, removeAddress} from '../../api/Clients';

export const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    clients: [],
    client: {},
    addresses: [],
    address: {},
    page: 1,
    loaded: false,
  },
  reducers: {
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    setActive: (state, action) => {
      state.client.Status = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    addAddress: (state, action) => {
      state.addresses.push(action.payload);
    }
  },
});

// Action creators are generated for each case reducer function
export const {setClients, setClient, setActive, setPage, setLoaded, setAddress, addAddress} = clientsSlice.actions;

export const getAllClients = () => async dispatch => {
  try {
    const response = await getClients();
    if (!response.error && response.status === 200) {
      dispatch(setClients(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const activeClient = (id, val) => async dispatch => {
  try {
    dispatch(setActive(!val));
    const response = await changeActive(id);
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
      };
    }
    dispatch(setActive(val));
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    dispatch(setActive(val));
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const getClientDetails = client => async dispatch => {
  try {
    const response = await getClient(client);
    if (!response.error && response.status === 200) {
      dispatch(setClient(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const editClient = (id, data) => async (dispatch) => {
  try {
    const response = await updateClient(id, data);
    if (!response.error && response.status === 200) {
      dispatch(setClient(response.data.customer));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const newClient = (data) => async (dispatch) => {
  try {
    const response = await createClient(data);
    if (!response.error && response.status === 200) {
      dispatch(setClient(response.data));
      return {
        status: "success",
        client: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const editAddress = (id, addr) => async (dispatch) => {
  try {
    const response = await updateAddress(id, addr);
    if (!response.error && response.status === 200) {
      dispatch(setAddress(response.data.customer));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const newAddress = (data) => async (dispatch) => {
  try {
    const response = await createAddress(data);
    if (!response.error && response.status === 200) {
      dispatch(addAddress(response.data.customer));
      return {
        status: "success",
        client: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const selectClients = state => state.clients.clients;
export const selectClient = state => state.clients.client;
export const selectPage = state => state.clients.page;
export const selectLoaded = state => state.clients.loaded;

export default clientsSlice.reducer;
