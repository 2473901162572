import {Row, Col, DatePicker, Button, Spin } from 'antd';
import styles from '../styles/Statistics.module.css';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { getKPIAverageTimes, getLocations } from '../../../api/kpi';
import LocationSelector from '../../../components/LocationSelector';

const AverageTimes = () => 
{
    const initialObj = {type:"number", val:"0", location: []};
    const Filtros = [
        {Id:1, Value:0, Text:"Hoy", Type:"number"}, 
        {Id:2, Value:-1, Text:"Ayer", Type:"number"},
        {Id:3, Value:-7, Text:"Últimos 7 días", Type:"number"},
        {Id:4, Value:-30, Text:"Últimos 30 días", Type:"number"}
    ];
    
    const { RangePicker } = DatePicker;

    const [datosTop, setdatosTop] = useState([]);
    const [loading, handleLoading] = useState(false);
    const [defaultFiltro, setDefaultFiltro] = useState(1);
    const [locations, setLocations] = useState([]);
    const [obj, setObj] = useState(initialObj);
    const [defaultDate, setDefaultDate] = useState('');
    const [defaultRange, setDefaultRange] = useState('');
    const [locationValues, setLocationValues] = useState([]);
    //const [defaultLocation, setdefaultLocation] = useState(0);

    useEffect(() => {
        getKPIAverageTimes(obj).then(response => {
            if(response.status === "success"){
                setdatosTop(response.tiempos);
            } else {
                alert("No se procesó la información.");
            }
            handleLoading(false);
        })

        getLocations().then(response => {
            setLocations(response.data);
        })
    }, [obj, defaultFiltro])

    
    const GetMonths = (dateString) => {
        let fecha = dateString.format("YYYY-MM");
        ResetObjByDates(fecha, "date");
        setDefaultDate(dateString);
        setDefaultFiltro(0);

        if(!(defaultRange === '')){
            setDefaultRange('');
        }
    };

    const GetRanges = (dateString) =>{
        let date1 = dateString[0].format('DD-MM-YYYY');
        let date2 = dateString[1].format('DD-MM-YYYY');
        let ranges = [date1, date2];
        ResetObjByDates(ranges, "range");
        setDefaultRange(dateString);
        setDefaultFiltro(0);

        if(!(defaultDate === '')){
            setDefaultDate('');
        }
    }

    /*
    const GetLocalidades = (id) => {
        let Localidad_Id = [];
        let Type = "";

        if(!(defaultDate === "")){
            Type = "date";

        }else if(!(defaultRange === "")){
            Type = "range";

        }else if(!(defaultFiltro === "")){
            Type = "filtro";
        };
        Localidad_Id.push(id);
        setdefaultLocation(id);
        ResetObjByLocations(Localidad_Id, Type);
    }
    */

    const updateLocationData = (payload) => {
        console.log(payload);
        let Type = "";
        if(!(defaultDate === "")){
            Type = "date";
        }
        else if(!(defaultRange === "")){
            Type = "range";
        }
        else if(!(defaultFiltro === 0)){
            Type = "filtro";
        };
       
        setLocationValues(payload);
        ResetObjByLocations(payload, Type);
        handleLoading(true);
    }

    const ResetObjByLocations = (id_location, type) => {
        let obj_query = {};
        if(type === "date"){
            obj_query = {type:"date", val:defaultDate.format("YYYY-MM"), location: id_location};
        }
        else if(type === "range"){
            let date1 = defaultRange[0].format('DD-MM-YYYY');
            let date2 = defaultRange[1].format('DD-MM-YYYY');
            let ranges = [date1, date2];
            obj_query = {type:"range", val:ranges, location: id_location};
        }
        else if(type === "filtro") {
            if(defaultFiltro === 1) {
                obj_query = {type:"number", val:"0", location: id_location};
            } 
            else if(defaultFiltro === 2){
                obj_query = {type:"number", val:"-1", location: id_location};
            }
            else if(defaultFiltro === 3){
                obj_query = {type:"number", val:"-7", location: id_location};
            }
            else if(defaultFiltro === 4){
                obj_query = {type:"number", val:"-30", location: id_location};
            }  
        }

        setObj(obj_query);
        handleLoading(true);

        //getKPIAverageTimes(obj_query).then(response => {
        //    if(response.status === "success"){
        //        setdatosTop(response.tiempos);
        //    }else{
        //        alert("No se procesó la información.");
        //    }
        //})
    }

    const ResetObjByDates = (date, type) => {
        setObj({type: type, val: date, location: []});
        handleLoading(true);
        //getKPIAverageTimes(obj).then(response => {
        //    if(response.status === "success"){
        //        setdatosTop(response.tiempos);
        //    }else{
        //        alert("No se procesó la información.");
        //    }
        //})
    }

    const ResetObj = (event) => 
    {
        //console.log(event);
        let objQuery = {type:"number", val:"0", location: locationValues};
        let ValorFiltro = Number(event.target.parentElement.getAttribute('data-key'));

        if(ValorFiltro === 2){
            objQuery = {type:"number", val:"-1", location: locationValues};
        }
        else if(ValorFiltro === 3){
            objQuery = {type:"number", val:"-7", location: locationValues};
        }
        else if(ValorFiltro === 4){
            objQuery = {type:"number", val:"-30", location: locationValues};
        }  

        setDefaultFiltro(ValorFiltro);
        setObj(objQuery);
        handleLoading(true);

        //getKPIAverageTimes(obj).then(response => {
        //    if(response.status === "success"){
        //        setdatosTop(response.tiempos);
        //    }else{
        //        alert("No se procesó la información.");
        //    }
        //})
    }

    const ChartAT = {
        series: [Number(datosTop.Aceptado), Number(datosTop.Preparando), Number(datosTop.Despachado)],
        formatter: function(value, { seriesIndex, dataPointIndex, w }) {
            return w.config.labels[seriesIndex] + " -  " + value.toFixed(0) + "min."
        },
        options: {
            chart: {
                type: 'pie',
            },
            labels: ['Aceptación', 'En Preparación', 'En Camino'],
            colors: ['#FEC728', '#585858', '#8FD400'],
            responsive: [{
                breakpoint: 480,
            }]
        }
    }
    
    return(
        <div>
            <div className={styles.titulo_reportes}>tiempos promedios</div>
            <div className={styles.mb_10}>
                <Row className={styles.contenedor_filtros}>
                    <Col span={12}>
                    {Filtros.map(flt => {
                            const FltSeleccionado = flt.Id === Number(defaultFiltro) ? styles.filtro_seleccionado : styles.filtro_general;
                            return(
                                <Button key={flt.Id} data-key={flt.Id} data-values={flt.Value} data-type={flt.Type} className={FltSeleccionado} onClick={ResetObj} >{flt.Text}</Button>
                            )
                        })}
                    </Col>
                    <Col span={12}>
                        <div className={styles.d_Flex}>
                            <DatePicker
                              onChange={GetMonths} 
                              value={defaultDate}
                              picker="month" 
                              className={styles.mr_2} 
                            />
                            <RangePicker
                             onChange={GetRanges}
                             value={defaultRange}
                             />
                        </div>
                    </Col>
                </Row>
                <Row className={styles.margin_top_10+" "+styles.margin_botton_10}>
                    <Col span={10}>
                        <LocationSelector dataSource={locations} selectedValues={locationValues} setSelectedValues={updateLocationData} />
                    </Col>
                </Row>
            </div>
            {
                loading ? (
                    <div className={styles.loader}><Spin size="large" /></div>
                ) : (
                    <div className={styles.card}>
                        <Row gutter={0}>
                            <Col span={24} offset={6} >
                                <Chart
                                    options={ChartAT.options}
                                    series={ChartAT.series}
                                    type="pie"
                                    width="65%"
                                    className={styles.area_chart_pie}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <div className={styles.font_weight_16+" "+styles.margin_4em}>* Tiempos promedios en minutos</div>
                        </Row>
                    </div>
                )
            }
        </div>
    )  
};

export default AverageTimes;