import { Select, Button, Row, Col, Modal, Spin } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Topping from "./Topping";
import Editor from "./Editor";
import Price from "./Price";
import styles from "../styles/ToppingsUI.module.css";

const { Option } = Select;

const ToppingsUI = (props) => {
  const {
    editorVisible,
    editTopping,
    newTopping,
    closeEditor,
    toppings,
    topping,
    category,
    loading,
    handleActive,
    confirmDelete,
    handleTopping,
    closeModalPrice,
    openModalPrice,
    modalPrice,
    changePrice,
    branches,
    branch,
    selectBranch,
    reorderToppings,
    reorderCategories,
  } = props;

  return (
    <div className={styles.toppingsView}>
      <Row>
        <Col span={24}>
          <div className={styles.header}>
            <h1>Toppings</h1>
            <div className={styles.actions}>
              <Select
                className={styles.input}
                loading={loading}
                disabled={loading}
                showSearch
                size="large"
                placeholder="Seleccionar Tienda"
                optionFilterProp="children"
                value={branch}
                onChange={selectBranch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {branches.map((branch) => (
                  <Option key={branch.id} value={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
              <Button
                onClick={openModalPrice}
                className={styles.submit}
                type="primary"
                size="large"
              >
                MODIFICAR PRECIO TOPPING
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      {!loading ? (
        <DragDropContext onDragEnd={reorderCategories}>
          <Droppable droppableId="categories" direction="horizontal">
            {(provided) => (
              <Row
                gutter={20}
                className={styles.toppingsWrap}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {toppings.map((category, index) => (
                  <Draggable
                    key={category.id}
                    draggableId={`category-${category.id}`}
                    index={index}
                  >
                    {(provided) => (
                      <Col
                        md={8}
                        lg={6}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Topping
                          category={category}
                          editTopping={editTopping}
                          newTopping={newTopping}
                          handleActive={handleActive}
                          indexCategory={index}
                          confirmDelete={confirmDelete}
                          reorderToppings={reorderToppings}
                        />
                      </Col>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Row>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      )}
      <Modal
        destroyOnClose
        visible={editorVisible}
        footer={false}
        closable={false}
        width={600}
        onCancel={closeEditor}
      >
        <Editor
          topping={topping}
          category={category}
          closeEditor={closeEditor}
          handleTopping={handleTopping}
          branch={branch}
        />
      </Modal>
      <Modal
        destroyOnClose
        footer={false}
        visible={modalPrice}
        width={400}
        closable={false}
        onCancel={closeModalPrice}
      >
        <Price close={closeModalPrice} changePrice={changePrice} />
      </Modal>
    </div>
  );
};

export default ToppingsUI;
