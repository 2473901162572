import axios from "axios";

const getInstance = async (country, file) => {
  //const baseURL = `http://localhost:8083/admin/rest`; 
  const baseURL = `https://${
    process.env.NODE_ENV === "production" ? "apillao" : "devapi"
  }.llaollaosv.com/${country || "admin"}/rest`;

  const timeout = 60000;
  const request = axios.create({
    baseURL,
    timeout,
    headers: {
      "Content-Type": file ? "multipart/form-data" : "application/json",
    },
  });
  /* request.interceptors.request.use(
    async config => {
      const token = localStorage.getItem('delos_user');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error)
  ) */
  return request;
};

export default getInstance;
