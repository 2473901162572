import instance from './Request';

export const getProducts = async branch => {
  const request = await instance();
  let data = await request
    .get(`/product/location/${branch}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getAnimations = async () => {
  const request = await instance();
  let data = await request
    .get('/product/get-customs')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const newProduct = async product => {
  const request = await instance(false, true);
  let data = await request
    .post('/product', product)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editProduct = async (id, product) => {
  const request = await instance(false, true);
  let data = await request
    .put(`/product/${id}`, product)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const deleteProduct = async id => {
  const request = await instance();
  let data = await request
    .delete(`/product/${id}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

