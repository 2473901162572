
import {Row, Col, DatePicker, Button, Table  } from 'antd';
import styles from '../styles/Statistics.module.css';
import { useEffect, useState } from 'react';
import { getKPIOrdersByZones } from '../../../api/kpi';

const OrdersByZones = () => 
{   
    const initialObj = {type:"number", val:"0", location: []}; 
    const Filtros = [
        {Id:1, Value:"0", Text:"Hoy", Type:"number"}, 
        {Id:2, Value:"-1", Text:"Ayer", Type:"number"},
        {Id:3, Value:"-7", Text:"Últimos 7 días", Type:"number"},
        {Id:4, Value:"-30", Text:"Últimos 30 días", Type:"number"}
    ];

    //useState
    const [datos, setDatos] = useState([]);
    const [loading, handleLoading] = useState(false);
    const [obj, setObj] = useState(initialObj);
    const [defaultFiltro, setDefaultFiltro] = useState(1);
    const [defaultDate, setDefaultDate] = useState('');
    const [defaultRange, setDefaultRange] = useState('');

    const { RangePicker } = DatePicker;

    //useEffect
    useEffect(() => {
        getKPIOrdersByZones(obj).then(response => {
            if(response.status === "success"){
                setDatos(response.zonas);
            } else {
                alert("No se procesó la información 1.");
            }
            handleLoading(false);
        })
    }, [obj, defaultFiltro]);


    const GetMonths = (dateString) => {
        let fecha = dateString.format("YYYY-MM");

        ResetObjByDates(fecha, "date");
        setDefaultDate(dateString);
        setDefaultFiltro(0);

        if(!(defaultRange === '')){
            setDefaultRange('');
        }
    };

    const GetRanges = (dateString) =>{
        let date1 = dateString[0].format('DD-MM-YYYY');
        let date2 = dateString[1].format('DD-MM-YYYY');
        let ranges = [date1, date2];
        ResetObjByDates(ranges, "range");
        setDefaultRange(dateString);
        setDefaultFiltro(0);

        if(!(defaultDate === '')){
            setDefaultDate('');
        }
    }

    const ResetObj = (event) =>{
        event.preventDefault(); 
        let ValorFiltro = Number(event.target.parentElement.getAttribute('data-key'));
        let objQuery = {type:"number", val:"0", location: []};

        if(ValorFiltro === 2){
            objQuery = {type:"number", val:"-1", location: []};
        }
        else if(ValorFiltro === 3){
            objQuery = {type:"number", val:"-7", location: []};
        }
        else if(ValorFiltro === 4){
            objQuery = {type:"number", val:"-30", location: []};
        }  
        setDefaultFiltro(ValorFiltro);
        setObj(objQuery);
        handleLoading(true);

        //getKPIOrdersByZones(obj).then(response => {
        //    if(response.status === "success"){
        //        setDatos(response.zonas);
        //    }else{
        //        alert("No se procesó la información.");
        //    }
        //})
    }

    const ResetObjByDates = (date, type) => {
        setObj({type: type, val: date, location: []});
        handleLoading(true);
        //getKPIOrdersByZones(obj).then(response => {
        //    if(response.status === "success"){
        //        setDatos(response.zonas);
        //    }else{
        //        alert("No se procesó la información.");
        //    }
        //})
    }

    const columns=[
        {
            title: 'Posición',
            dataIndex: 'id',
            key: 'Position',
            align: 'center'
        },
        {
            title: 'Zona',
            dataIndex: 'nombre',
            key: 'Producto'
        },
        {
            title: 'Pedidos',
            dataIndex: 'valor',
            key: 'Quanty',
            align: 'center',
        }
    ]

    return(
        <div>
            <div className={styles.titulo_reportes}>pedidos por zona</div>
            <div className={styles.mb_10}>
                <Row className={styles.contenedor_filtros+" "+styles.margin_botton_10}>
                    <Col span={12}>
                        {Filtros.map(flt => {
                            const FltSeleccionado = flt.Id === Number(defaultFiltro) ? styles.filtro_seleccionado : styles.filtro_general;
                            return(
                                <Button key={flt.Id} data-key={flt.Id} data-values={flt.Value} data-type={flt.Type} className={FltSeleccionado} onClick={ResetObj} >{flt.Text}</Button>
                            )
                        })}
                    </Col>
                    <Col span={12}>
                        <div className={styles.d_Flex}>
                            <DatePicker
                              onChange={GetMonths} 
                              value={defaultDate}
                              picker="month" 
                              className={styles.mr_2} 
                            />
                            <RangePicker
                             onChange={GetRanges}
                             value={defaultRange}
                             />
                        </div>
                    </Col>
                </Row>
                {/*
                    <Row className={styles.margin_top_10+" "+styles.margin_botton_10}>
                        <Col span={8} className={styles.mr_5}>
                            <Select
                                showSearch
                                className={styles.cien_por_cien+" "+styles.font_weight_14}
                                placeholder="Todas los departamentos"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                <Option value="1">Not Identified</Option>
                            </Select>
                        </Col>
                        <Col span={8}>
                            <Select
                                showSearch
                                className={styles.cien_por_cien+" "+styles.font_weight_14}
                                placeholder="Todas los productos h"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                <Option value="1">Not Identified</Option>
                            </Select>
                        </Col>
                    </Row>
                            */}
            </div>
            <div className={styles.view}>
                <div>
                    <Table loading={loading} dataSource={datos} columns={columns}/>
                </div>
            </div>
        </div>
    )  
};

export default OrdersByZones;