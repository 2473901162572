import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Upload,
  Button,
  message,
  Tooltip,
  Switch,
} from "antd";
import { useDispatch } from "react-redux";
import { createTopping, updateTopping } from "../../../redux/slices/toppings";
import { validateFloatKeyPress } from "../../../utils/format";
import { normFile, beforeUpload, dummyRequest } from "../../../utils/images";
import Close from "../../../components/Close";
import EmptyTopping from "../assets/emptyTopping.png";
import styles from "../styles/Editor.module.css";

const Editor = ({ topping, category, closeEditor, handleTopping, branch }) => {
  const [sending, handleSending] = useState(false);
  const [baseImg, handleBaseImg] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      if (topping) {
        const newValues = {
          name: topping.name,
          price: topping.price,
          suggested: !!topping.sugerido,
        };
        handleBaseImg(topping.icon_image || "");
        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, [topping]);

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      if (baseImg && baseImg.length) {
        const info = new FormData();
        if (topping) {
          info.append("id", topping.id);
        }
        info.append("location", branch);
        info.append("name", values.name);
        info.append("description", values.name);
        info.append("price", values.price);
        info.append("sugerido", values.suggested);
        info.append("category", category.id);
        info.append(
          "icon_image",
          values.iconImage && values.iconImage.length
            ? values.iconImage[0]?.originFileObj
            : baseImg
        );
        info.append(
          "image",
          values.image && values.image.length
            ? values.image[0]?.originFileObj
            : topping
            ? topping.image
            : ""
        );
        info.append(
          "image2",
          values.image2 && values.image2.length
            ? values.image2[0]?.originFileObj
            : topping
            ? topping.image2
            : ""
        );
        info.append(
          "fall_image1",
          values.imageFall1 && values.imageFall1.length
            ? values.imageFall1[0]?.originFileObj
            : topping
            ? topping.fall_image1
            : ""
        );
        info.append(
          "fall_image2",
          values.imageFall2 && values.imageFall2.length
            ? values.imageFall2[0]?.originFileObj
            : topping
            ? topping.fall_image2
            : ""
        );
        info.append(
          "fall_image3",
          values.imageFall3 && values.imageFall3.length
            ? values.imageFall3[0]?.originFileObj
            : topping
            ? topping.fall_image3
            : ""
        );
        info.append(
          "fall_image4",
          values.imageFall4 && values.imageFall4.length
            ? values.imageFall4[0]?.originFileObj
            : topping
            ? topping.fall_image4
            : ""
        );

        if (topping) {
          const response = await dispatch(
            updateTopping(topping.id, info, category)
          );
          if (response.status === "success") {
            message.success("Cambios realizados con éxito");
          } else {
            message.error("¡Hubo un problema! Inténtalo de nuevo");
          }
        } else {
          const response = await dispatch(createTopping(info, category));
          if (response.status === "success") {
            message.success("Cambios realizados con éxito");
            handleTopping(response.topping);
          } else {
            message.error("¡Hubo un problema! Inténtalo de nuevo");
          }
        }
      } else {
        message.error("Seleciona una imagen válida");
      }

      handleSending(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <div className={styles.header}>
            <span>{topping ? "Editar topping" : "Agregar topping"}</span>
            <Close action={closeEditor} />
          </div>
        </Col>
      </Row>
      <Form
        form={form}
        name="product-editor"
        autoComplete="off"
        colon={false}
        requiredMark={false}
        className={styles.form}
        onFinish={save}
      >
        <Row>
          <Col span={12}>
            <div className={styles.imgWrap}>
              <img src={EmptyTopping} alt="" className={styles.empty} />
              <div
                className={styles.imgTopping}
                style={{ backgroundImage: `url(${baseImg})` }}
              />
            </div>
          </Col>
          <Col span={12}>
            <span className={styles.titleCategory}>
              {`CATEGORÍA: ${category.name}`}
            </span>
            <div className={styles.switchWrapper}>
              <Form.Item
                label={<span className={styles.label}>SUGERIDO</span>}
                name="suggested"
              >
                <Switch />
              </Form.Item>
            </div>
            <Form.Item
              label={<span className={styles.label}>NOMBRE</span>}
              name="name"
              className={styles.itemColumn}
              rules={[{ required: true, message: "Ingresar nombre" }]}
            >
              <Input size="large" className={styles.input} />
            </Form.Item>
            <Form.Item
              label={<span className={styles.label}>PRECIO INDIVIDUAL</span>}
              name="price"
              normalize={validateFloatKeyPress}
              rules={[{ required: true, message: "Ingresar precio" }]}
              className={styles.itemColumn}
            >
              <Input size="large" className={styles.input} prefix="$" />
            </Form.Item>
            <Form.Item
              name="iconImage"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              label={<span className={styles.label}>IMAGEN TOPPING</span>}
              className={styles.itemColumn}
              extra={
                <span className={styles.center}>
                  (Formatos jpg o png de 500x400px)
                </span>
              }
            >
              <Upload
                showUploadList={false}
                customRequest={(e) => dummyRequest(e, handleBaseImg)}
                beforeUpload={beforeUpload}
                className={styles.upload}
              >
                <Button className={styles.uploadImage} block>
                  Imagen principal
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Tooltip
              placement="bottom"
              title="Posición final del topping para la animación de tarrina después de la secuencia de caídas y también la imagen cuando es categoría de salsas."
            >
              <Form.Item
                name="image"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                extra={
                  <span className={styles.center}>
                    (Formatos png de 320x435px)
                  </span>
                }
              >
                <Upload
                  maxCount={1}
                  customRequest={(e) => dummyRequest(e, () => {})}
                  beforeUpload={beforeUpload}
                  listType="picture"
                >
                  <Button className={styles.uploadImage}>Imagen final</Button>
                </Upload>
              </Form.Item>
            </Tooltip>
          </Col>
          <Col span={12}>
            <Tooltip
              placement="bottom"
              title="Imagen utilizada para animación Llaovasito, la cual es la imagen de la salsa a mostrar dentro de este."
            >
              <Form.Item
                name="image2"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                extra={
                  <span className={styles.center}>
                    (Formatos png de 320x435px)
                  </span>
                }
              >
                <Upload
                  maxCount={1}
                  customRequest={(e) => dummyRequest(e, () => {})}
                  beforeUpload={beforeUpload}
                  listType="picture"
                >
                  <Button className={styles.uploadImage}>
                    Imagen llaovasito
                  </Button>
                </Upload>
              </Form.Item>
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            {/* <Tooltip
              placement="bottom"
              title="">
            </Tooltip> */}
            <Tooltip
              placement="bottom"
              title="Imagen para la animación de tarrina que es la secuencia de caídas del topping."
            >
              <Form.Item
                name="imageFall1"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                extra={
                  <span className={styles.center}>
                    (Formatos png de 320x435px)
                  </span>
                }
              >
                <Upload
                  maxCount={1}
                  customRequest={(e) => dummyRequest(e, () => {})}
                  beforeUpload={beforeUpload}
                  listType="picture"
                >
                  <Button className={styles.uploadImage}>Imagen caida 1</Button>
                </Upload>
              </Form.Item>
            </Tooltip>
          </Col>
          <Col span={12}>
            <Tooltip
              placement="bottom"
              title="Imagen para la animación de tarrina que es la secuencia de caídas del topping."
            >
              <Form.Item
                name="imageFall2"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                extra={
                  <span className={styles.center}>
                    (Formatos png de 320x435px)
                  </span>
                }
              >
                <Upload
                  maxCount={1}
                  customRequest={(e) => dummyRequest(e, () => {})}
                  beforeUpload={beforeUpload}
                  listType="picture"
                >
                  <Button className={styles.uploadImage}>Imagen caida 2</Button>
                </Upload>
              </Form.Item>
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Tooltip
              placement="bottom"
              title="Imagen para la animación de tarrina que es la secuencia de caídas del topping."
            >
              <Form.Item
                name="imageFall3"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                extra={
                  <span className={styles.center}>
                    (Formatos png de 320x435px)
                  </span>
                }
              >
                <Upload
                  maxCount={1}
                  customRequest={(e) => dummyRequest(e, () => {})}
                  beforeUpload={beforeUpload}
                  listType="picture"
                >
                  <Button className={styles.uploadImage}>Imagen caida 3</Button>
                </Upload>
              </Form.Item>
            </Tooltip>
          </Col>
          <Col span={12}>
            <Tooltip
              placement="bottom"
              title="Imagen utilizada para animación Llaovasito, y es la posición final que tendrá el topping después de la caída en un llaovacito."
            >
              <Form.Item
                name="imageFall4"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                extra={
                  <span className={styles.center}>
                    (Formatos png de 320x435px)
                  </span>
                }
              >
                <Upload
                  maxCount={1}
                  customRequest={(e) => dummyRequest(e, () => {})}
                  beforeUpload={beforeUpload}
                  listType="picture"
                >
                  <Button className={styles.uploadImage}>Imagen caida 4</Button>
                </Upload>
              </Form.Item>
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.submitWrap}>
              <Form.Item className={styles.itemSubmit}>
                <Button
                  loading={sending}
                  className={styles.submit}
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  GUARDAR TOPPING
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Editor;
