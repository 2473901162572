import {Table, Spin, Modal} from 'antd';
import moment from 'moment';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import Editor from './Editor';
import styles from '../styles/PagesUI.module.css';

const PagesUI = props => {
  const {
    loading,
    pages,
    page,
    openEditor,
    editorVisible,
    closeEditor,
  } = props;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Título',
      dataIndex: 'title',
    },
    {
      title: 'Creación',
      dataIndex: 'date_created',
      align: 'center',
      render: val => moment(val).format('DD/MM/YY - hh:mma'),
    },
    {
      title: 'Editar',
      align: 'center',
      dataIndex: 'id',
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => openEditor(record)}>
            <Edit />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.view}>
      <Header
        back="/contenidos"
        title="Páginas institucionales"
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={pages}
            rowKey="id"
          />
        </div>
      )}
      <Modal
        destroyOnClose
        closable={false}
        visible={editorVisible}
        footer={false}
        onCancel={closeEditor}
        width={1200}
      >
        <Editor
          close={closeEditor}
          page={page}
        />
      </Modal>
    </div>
  );
};

export default PagesUI;
