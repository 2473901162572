import {Button, Table, Spin, Modal, Select, Switch} from 'antd';
import moment from 'moment';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import {getColumnSearchProps} from '../../../utils/tables';
import Editor from './Editor';
import styles from '../styles/CategoriesUI.module.css';

const {Option} = Select;

const CategoriesUI = props => {
  const {
    loading,
    categories,
    category,
    branches,
    openEditor,
    editorVisible,
    closeEditor,
    newCategory,
    confirmDelete,
    handleCategory,
    handleActive,
    branch,
    selectBranch,
    types,
  } = props;

  const getTypeName = typeId => {
    let name = '-';
    const index = types.findIndex(el => el.id === typeId);
    if (index > -1) {
      name = types[index].name;
    }
    return name;
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      align: 'center',
      filters: types.map(type => ({
        text: type.name,
        value: type.id,
      })),
      onFilter: (val, record) => record.type === val,
      render: val => getTypeName(val), 
    },
    {
      title: 'Creado en',
      dataIndex: 'date_created',
      align: 'center',
      render: val => moment(val).format('DD/MM/YYYY'), 
    },
    {
      title: 'Activo',
      align: 'center',
      dataIndex: 'active',
      render: (val, record) => (
        <Switch checked={val} onChange={() => handleActive(record.id, val)} />
      ),
    },
    {
      title: 'Editar',
      align: 'center',
      dataIndex: 'id',
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => openEditor(record)}>
            <Edit />
          </div>
          <div className={styles.divider} />
          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.view}>
      <Header
        title="Categorías"
        back="/ajustes"
        actions={
          <div className={styles.actions}>
            <Select
              className={styles.input}
              loading={loading}
              disabled={loading}
              showSearch
              size="large"
              placeholder="Seleccionar Tienda"
              optionFilterProp="children"
              value={branch}
              onChange={selectBranch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {branches.map(branch => (
                <Option key={branch.id} value={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
            <Button
              onClick={newCategory}
              type="primary"
              size="large"
              className="main-button">
              NUEVA CATEGORÍA
            </Button>
          </div>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={categories}
            rowKey="id"
          />
        </div>
      )}
      <Modal
        destroyOnClose
        closable={false}
        visible={editorVisible}
        footer={false}
        onCancel={closeEditor}
        width={600}
      >
        <Editor
          close={closeEditor}
          category={category}
          handleCategory={handleCategory}
          branch={branch}
          types={types}
        />
      </Modal>
    </div>
  );
};

export default CategoriesUI;
