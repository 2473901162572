import {useState} from 'react';
import {Button, Input, message} from 'antd';
import {validateFloatKeyPress} from '../../../utils/format';
import Close from '../../../components/Close';
import styles from '../styles/Price.module.css';

const Price = ({close, changePrice}) => {
  const [sending, handleSending] = useState(false);
  const [price, handlePrice] = useState('');

  const finishMonitorOrder = async () => {
    if (price.length) {
      handleSending(true);
      await changePrice(parseFloat(price));
      handleSending(false);
      close();
    } else {
      message.error('Ingresa el nuevo precio');
    }
  };

  const newPrice = async e => {
    const val = await validateFloatKeyPress(e.target.value);
    if (val || val === '') {
      handlePrice(val);
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <span className={styles.title}>
          Modificar precio topping 
        </span>
        <Close action={close} />
      </div>
      <div className={styles.body}>
        <Input
          className={styles.input}
          placeholder="0.00"
          prefix="$"
          size="large"
          value={price}
          onChange={newPrice}
        />
      </div>
      <div className={styles.action}>
        <Button
          loading={sending}
          onClick={finishMonitorOrder}
          size="large"
          type="primary"
          className={styles.submit}>
          GUARDAR CAMBIOS
        </Button>
      </div>
    </div>
  );
};

export default Price;
