import instance from "./Request";

export const getPromos = async () => {
  const request = await instance();
  let data = await request.get("/promos").catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getTypes = async () => {
  const request = await instance();
  let data = await request.get("/promos/list-types").catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const changePromoActive = async (promo) => {
  const request = await instance();
  let data = await request
    .post("/promos/activate", { id: promo })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const newPromo = async (promo) => {
  const request = await instance();
  let data = await request.post("/promos", promo).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const editPromo = async (id, promo) => {
  const request = await instance();
  let data = await request.put(`/promos/${id}`, promo).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const deletePromo = async (id) => {
  const request = await instance();
  let data = await request.delete(`/promos/${id}`).catch((error) => {
    return {
      error,
    };
  });
  return data;
};
