import instance from "./Request";

export const getToppings = async (branch) => {
  const request = await instance();
  let data = await request
    .get(`/toppings/location/${branch}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changeActive = async (topping) => {
  const request = await instance();
  let data = await request
    .post("/toppings/activate", { topping })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const newTopping = async (topping) => {
  const request = await instance(false, true);
  let data = await request.post("/toppings", topping).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const editTopping = async (id, topping) => {
  const request = await instance(false, true);
  let data = await request.put(`/toppings/${id}`, topping).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const deleteTopping = async (id) => {
  const request = await instance();
  let data = await request.delete(`/toppings/${id}`).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const updatePrice = async (newprice) => {
  const request = await instance();
  let data = await request
    .post("/toppings/all-price", { newprice })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changeOrder = async (position, id, type) => {
  const request = await instance();
  let data = await request
    .post("/toppings/set-order", { order: position, topping: id, type })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
