import { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, message } from "antd";
import { useDispatch } from "react-redux";
import { updatePage } from "../../../redux/slices/pages";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Close from "../../../components/Close";
import styles from "../styles/Editor.module.css";

const Editor = ({ close, page }) => {
  const [sending, handleSending] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const useEditor = [7, 8, 6].includes(page.id);
  const [editorContent, setEditorContent] = useState(page.content);

  useEffect(() => {
    const initialFetch = async () => {
      const newValues = {
        content: page.content,
      };
      form.setFieldsValue(newValues);
    };
    initialFetch();
  }, [page]);

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      const info = {
        title: page.title,
        content: useEditor ? editorContent : values.content,
        id: page.id,
      };
      const response = await dispatch(updatePage(info));
      if (response.status === "success") {
        handleSending(false);
        message.success("Cambios realizados con éxito");
      } else {
        handleSending(false);
        message.error("¡Hubo un problema! Inténtalo de nuevo");
      }
    } catch (e) {}
  };

  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>{page.title}</span>
        <Close action={close} />
      </div>
      <Form
        name="branch-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
      >
        <Row>
          <Col span={24}>
            {useEditor ? (
              <ReactQuill
                theme="snow"
                value={editorContent}
                onChange={setEditorContent}
              />
            ) : (
              <Form.Item
                name="content"
                className={styles.itemColumn}
                rules={[{ required: true, message: "Ingresar contenido" }]}
              >
                <Input.TextArea
                  rows={20}
                  size="large"
                  className={styles.input}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className={styles.itemSubmit}>
              <Button
                loading={sending}
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit"
              >
                GUARDAR CAMBIOS
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Editor;
