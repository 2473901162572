import instance from './Request';

export const getBlogs = async () => {
  const request = await instance();
  let data = await request
    .get('/blog')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getCategories = async () => {
  const request = await instance();
  let data = await request
    .get('/blog/categories')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const newBlog = async blog => {
  const request = await instance(false, true);
  let data = await request
    .post('/blog', blog)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editBlog = async (id, blog) => {
  const request = await instance(false, true);
  let data = await request
    .put(`/blog/${id}`, blog)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const deleteBlog = async id => {
  const request = await instance();
  let data = await request
    .delete(`/blog/${id}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
