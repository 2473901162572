import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  DatePicker,
  Transfer,
} from "antd";
import { useDispatch } from "react-redux";
import moment from "moment";
import cx from "classnames";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import { updatePromo } from "../../../redux/slices/promos";
import { validateFloatKeyPress } from "../../../utils/format";
import Close from "../../../components/Close";
import styles from "../styles/EditorPromo.module.css";
const { RangePicker } = DatePicker;

const getTransferFormat = (list) =>
  list.map((item) => ({
    key: item.id,
    title: item.name,
    description: item.description,
  }));

const EditorPromo = ({
  close,
  promo,
  handlePromo,
  products,
  toppings,
  setActivePromo,
}) => {
  const [sending, handleSending] = useState(false);
  const [form] = Form.useForm();
  const [typePromo, setTypePromo] = useState(1);
  const [productsList] = useState(getTransferFormat(products));
  const [productsSelected, setProductsSelectedList] = useState(
    promo.products || []
  );
  const [toppingsList] = useState(getTransferFormat(toppings));
  const [toppingsSelected, setToppingsSelectedList] = useState(
    promo.toppings || []
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      const newValues = {
        name: promo.name,
        type: promo.type,
        description: promo.description,
        duration: [moment(promo.time_begin), moment(promo.time_end)],
        price: promo.price || 0,
      };
      form.setFieldsValue(newValues);
      setTypePromo(promo.type);
    };
    initialFetch();
  }, [promo]);

  const save = async () => {
    try {
      handleSending(true);
      if (
        typePromo !== 1 ||
        (toppingsSelected.length && productsSelected.length)
      ) {
        const values = await form.validateFields();
        const info = {
          name: values.name,
          description: values.description,
          timeBegin: values.duration[0].format("YYYY-MM-DD HH:mm:ss"),
          timeEnd: values.duration[1].format("YYYY-MM-DD HH:mm:ss"),
        };
        if (typePromo !== 3) {
          info.price = parseFloat(values.price);
        } else if (typePromo === 1) {
          info.products = productsSelected;
          info.toppings = toppingsSelected;
        }
        const response = await dispatch(updatePromo(promo.id, info));
        if (response.status === "success") {
          setActivePromo(response.promo);
          message.success("Cambios realizados con éxito");
        } else if (response.type === "incomplete") {
          handleSending(false);
          message.warning("Ingresa todos los datos solicitados");
        } else {
          message.error("¡Hubo un problema! Inténtalo de nuevo");
        }
      } else {
        message.error("Selecciona los toppings y productos que aplican");
      }
      handleSending(false);
    } catch (e) {
      message.error("¡Hubo un problema! Inténtalo de nuevo");
    }
  };

  const onChange = (nextTargetKeys) => {
    setProductsSelectedList(nextTargetKeys);
  };

  const onChangeToppings = (nextTargetKeys) => {
    setToppingsSelectedList(nextTargetKeys);
  };

  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>
          {promo ? "Editar promoción" : "Nueva promoción"}
        </span>
        <Close action={close} />
      </div>
      <Form
        name="branch-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
        className={styles.form}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label={<span className={styles.label}>NOMBRE</span>}
              name="name"
              className={styles.itemColumn}
              rules={[{ required: true, message: "Ingresar nombre" }]}
            >
              <Input size="large" className={styles.input} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={<span className={styles.label}>DESCRIPCIÓN</span>}
              name="description"
              className={styles.itemColumn}
              rules={[{ required: true, message: "Ingresar descripción" }]}
            >
              <Input.TextArea size="large" className={styles.input} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={<span className={styles.label}>DURACIÓN</span>}
              name="duration"
              className={styles.itemColumn}
              rules={[{ required: true, message: "Ingresar duración" }]}
            >
              <RangePicker
                showTime
                className={styles.input}
                size="large"
                locale={locale}
              />
            </Form.Item>
          </Col>
        </Row>
        {typePromo !== 1 && (
          <Row>
            <Col span={24}>
              <Form.Item
                label={<span className={styles.label}>PRECIO</span>}
                name="price"
                className={styles.itemColumn}
                rules={[{ required: true, message: "Ingresar precio" }]}
                normalize={validateFloatKeyPress}
              >
                <Input
                  className={styles.input}
                  placeholder="0.00"
                  prefix="$"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {typePromo === 1 && (
          <>
            <div className={styles.transferProducts}>
              <span className={styles.label}>PRODUCTOS</span>
              <Transfer
                dataSource={productsList}
                showSearch
                titles={["Productos", "Seleccionados"]}
                targetKeys={productsSelected}
                render={(item) => item.title}
                onChange={onChange}
                oneWay
              />
            </div>
            <div
              className={cx(styles.transferProducts, styles.transferToppings)}
            >
              <span className={styles.label}>TOPPINGS</span>
              <Transfer
                dataSource={toppingsList}
                showSearch
                titles={["Toppings", "Seleccionados"]}
                targetKeys={toppingsSelected}
                render={(item) => item.title}
                onChange={onChangeToppings}
                oneWay
              />
            </div>
          </>
        )}
        <Row>
          <Col span={24}>
            <Form.Item className={styles.itemSubmit}>
              <Button
                loading={sending}
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit"
              >
                GUARDAR PROMOCIÓN
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditorPromo;
