import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message} from 'antd';
import {getAllPages, selectPages} from '../../redux/slices/pages';
import PagesUI from './components/PagesUI';

const Pages = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [page, handlePage] = useState(null);
  const pages = useSelector(selectPages);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      const [response] = await Promise.all([
        dispatch(getAllPages()),
      ]);
      if (response.status !== 'success') {
        message.error('¡Hubo un problema!');
      }
      handleLoading(false);
    };
    initialFetch();
  }, [dispatch]);


  const openEditor = pageToEdit => {
    handlePage(pageToEdit);
    handleEditorVisible(true);
  };

  const closeEditor = () => {
    handlePage(null);
    handleEditorVisible(false);
  };

  return (
    <PagesUI
      loading={loading}
      page={page}
      pages={pages}
      openEditor={openEditor}
      editorVisible={editorVisible}
      closeEditor={closeEditor}
    />
  );
}

export default Pages;
