import { Checkbox } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as Add } from "../../../assets/icons/add-round.svg";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import styles from "../styles/Topping.module.css";

const Topping = ({
  category,
  newTopping,
  editTopping,
  handleActive,
  indexCategory,
  confirmDelete,
  reorderToppings,
}) => {
  const Item = ({ topping, index }) => (
    <Draggable draggableId={`topping-${topping.id}`} index={index}>
      {(provided) => (
        <div
          className={styles.option}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Checkbox
            checked={topping.active}
            onChange={() =>
              handleActive(topping.id, indexCategory, index, topping.active)
            }
            className={styles.check}
          >
            {topping.name}
          </Checkbox>
          <Edit
            className={styles.edit}
            onClick={() => editTopping(topping, category)}
          />
          <div className={styles.divider} />
          <Trash
            className={styles.trash}
            onClick={() => confirmDelete(topping.id, indexCategory, index)}
          />
        </div>
      )}
    </Draggable>
  );

  return (
    <DragDropContext onDragEnd={reorderToppings}>
      <div className={styles.topping}>
        <div className={styles.header}>
          <span>{category.name}</span>
          <Add onClick={() => newTopping(category)} className={styles.add} />
        </div>
        <Droppable droppableId={`category-${category.id}`}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.options}
            >
              {category.toppings.map((topping, index) => (
                <Item key={topping.id} topping={topping} index={index} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default Topping;
