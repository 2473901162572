import { useState } from "react";
import { Button, TimePicker, message } from "antd";
import Close from "../../../components/Close";
import styles from "../styles/Schedule.module.css";

const format = "HH:mm";

const Schedule = ({ close, times, setTimes, updateSchedule }) => {
  const [sending, handleSending] = useState(false);

  const finishMonitorOrder = async () => {
    if (times) {
      handleSending(true);
      await updateSchedule();
      handleSending(false);
      close();
    } else {
      message.error("Ingresa el horario de delivery");
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <span className={styles.title}>Horario delivery</span>
        <Close action={close} />
      </div>
      <div className={styles.body}>
        <TimePicker.RangePicker
          format={format}
          className={styles.input}
          placeholder={["Apertura", "Cierre"]}
          value={times}
          onChange={(dates) => setTimes(dates)}
          size="large"
        />
      </div>
      <div className={styles.action}>
        <Button
          loading={sending}
          onClick={finishMonitorOrder}
          size="large"
          type="primary"
          className={styles.submit}
        >
          GUARDAR CAMBIOS
        </Button>
      </div>
    </div>
  );
};

export default Schedule;
