import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message} from 'antd';
import {
  getAllProducts,
  selectProducts,
  getAnimationsList,
} from '../../redux/slices/products';
import {getAllToppings, selectToppings} from '../../redux/slices/toppings';
import {getAllBranches, selectBranches} from '../../redux/slices/branches';
import ProductsUI from './components/ProductsUI';

const Products = () => {
  const [loading, handleLoading] = useState(true);
  const [product, handleProduct] = useState(null);
  const [type, handleType] = useState('');
  const [branch, handleBranch] = useState(null);
  const [animations, handleAnimations] = useState([]);
  const products = useSelector(selectProducts);
  const toppings = useSelector(selectToppings);
  const branches = useSelector(selectBranches);
  const dispatch = useDispatch();

  const getProducts = useCallback(async selectedBranch => {
    handleLoading(true);
    const [response, response_animations] = await Promise.all([
      dispatch(getAllProducts(selectedBranch)),
      dispatch(getAnimationsList()),
      dispatch(getAllToppings(selectedBranch)),
    ]);
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    } else {
      handleAnimations(response_animations.animations);
    }
    handleLoading(false);
  }, [dispatch]);

  const initialFetch = useCallback(async () => {
    handleLoading(true);
    const response_branches = await dispatch(getAllBranches());
    if (response_branches.status === 'success') {
      handleBranch(response_branches.first);
      await getProducts(response_branches.first);
    } else {
      message.error('¡Hubo un problema!');
    }
    handleLoading(false);
  }, [dispatch, getProducts]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const selectProduct = prdct => {
    handleType('edit');
    handleProduct(prdct);
  };

  const newProduct = () => {
    handleType('new');
    handleProduct(null);
  };

  const resetProduct = () => {
    handleType('');
    handleProduct(null);
  };

  const selectBranch = e => {
    handleBranch(e);
    handleProduct(null);
    handleType('');
    getProducts(e);
  };

  return (
    <ProductsUI
      products={products}
      loading={loading}
      selectProduct={selectProduct}
      newProduct={newProduct}
      type={type}
      product={product}
      toppings={toppings}
      handleProduct={handleProduct}
      resetProduct={resetProduct}
      handleType={handleType}
      selectBranch={selectBranch}
      branch={branch}
      branches={branches}
      animations={animations}
    />
  );
}

export default Products;
