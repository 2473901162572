import instance from './Request';

export const getPages = async () => {
  const request = await instance();
  let data = await request
    .get('/pages')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editPages = async page => {
  const request = await instance();
  let data = await request
    .put(`/pages/${page.id}`, page)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
