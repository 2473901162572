import { Row, Col, Modal, Spin, Switch } from "antd";
import cx from "classnames";
import moment from "moment";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import { ReactComponent as Deactivate } from "../../../assets/icons/deactivate.svg";
import { ReactComponent as Add } from "../../../assets/icons/add-round.svg";
import { ReactComponent as Gift } from "../../../assets/icons/regalo.svg";
import Editor from "./Editor";
import EditorPromo from "./EditorPromo";
import styles from "../styles/FrontpageUI.module.css";

const FrontpageUI = (props) => {
  const {
    loading,
    editorVisible,
    editBanner,
    newBanner,
    closeEditor,
    banner,
    handleBanner,
    banners,
    handleActive,
    confirmDelete,
    promos,
    activePromo,
    setActivePromo,
    editorPromoVisible,
    editPromo,
    closeEditorPromo,
    products,
    toppings,
    activatePromo,
  } = props;

  const Banner = ({ slide, index }) => (
    <div className={styles.banner}>
      <Row gutter={5}>
        <Col span={20}>
          <div
            className={cx(styles.bannerPhoto, {
              [styles.innactive]: !slide.active,
            })}
            style={{ backgroundImage: `url(${slide.image})` }}
          />
        </Col>
        <Col span={4}>
          <div className={styles.bannerActions}>
            <div
              className={styles.actionWrap}
              onClick={() => editBanner(slide)}
            >
              <Edit />
            </div>
            <div
              className={styles.actionWrap}
              onClick={() => handleActive(slide.id, index, slide.active)}
            >
              <Deactivate />
            </div>
            <div
              className={styles.actionWrap}
              onClick={() => confirmDelete(slide.id)}
            >
              <Trash />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col span={24}>
          <div className={styles.header}>
            <h1>Portada</h1>
          </div>
        </Col>
      </Row>
      {!loading ? (
        <Row gutter={20} className={styles.toppingsWrap}>
          <Col md={24} lg={6}>
            <div className={styles.sectionWrap}>
              <div className={styles.header}>
                <span>BANNERS</span>
              </div>
              <div className={styles.section}>
                {banners.map((slide, index) => (
                  <Banner key={slide.id} slide={slide} index={index} />
                ))}
                <div className={styles.newBtn} onClick={newBanner}>
                  <Add />
                  <span>BANNER NUEVO</span>
                </div>
              </div>
            </div>
          </Col>
          <Col md={24} lg={18}>
            <div className={styles.sectionWrap}>
              <div className={styles.header}>
                <span>PROMOCIONES</span>
              </div>
              <div className={styles.section}>
                <div className={styles.promosHeaderWrap}>
                  <Row>
                    <Col span={8}>
                      <div
                        className={cx(
                          styles.promosHeader,
                          styles.promoTypeHeader
                        )}
                      >
                        TIPO DE PROMOCIÓN
                      </div>
                    </Col>
                    <Col span={16}>
                      <div className={styles.promosHeader}>DETALLE</div>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col span={8}>
                    <div
                      className={cx(styles.promoList, styles.promoTypeHeader)}
                    >
                      {promos.map((promo) => (
                        <div
                          className={styles.promoItem}
                          onClick={() => setActivePromo(promo)}
                        >
                          <span
                            className={cx({
                              [styles.activeLabel]: promo.id === activePromo.id,
                            })}
                          >
                            {promo.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col span={16}>
                    <div className={styles.promoDetail}>
                      <Row>
                        <Col span={19}>
                          <div className={styles.promoActive}>
                            <div className={styles.promoIcon}>
                              <Gift />
                            </div>
                            <div className={styles.activePromoName}>
                              <span className={styles.labelName}>Nombre</span>
                              <span className={styles.promoName}>
                                {activePromo.name}
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col span={5}>
                          <div className={styles.activeWrapper}>
                            <span>PROMOCIÓN ACTIVADA</span>
                            <Switch
                              checked={activePromo.active}
                              onChange={activatePromo}
                            />
                            <Edit className={styles.edit} onClick={editPromo} />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <div className={styles.promoActiveInfo}>
                            <div className={styles.promoInfoItem}>
                              <span className={styles.promoInfoLabel}>
                                DESCRIPCIÓN
                              </span>
                              <span className={styles.promoInfoValue}>
                                {activePromo.description}
                              </span>
                            </div>
                            <div className={styles.promoInfoItem}>
                              <span className={styles.promoInfoLabel}>
                                PERIODO DE ACTIVACIÓN
                              </span>
                              <span className={styles.promoInfoValue}>
                                {`${moment(activePromo.time_begin).format(
                                  "DD/MM/YYYY HH:mm"
                                )} - ${moment(activePromo.time_end).format(
                                  "DD/MM/YYYY HH:mm"
                                )}`}
                              </span>
                            </div>
                            {activePromo.type === 3 && (
                              <div className={styles.promoInfoItem}>
                                <span className={styles.promoInfoLabel}>
                                  PRECIO
                                </span>
                                <span className={styles.promoInfoValue}>
                                  {`$${activePromo.price || 0}`}
                                </span>
                              </div>
                            )}
                            {activePromo.type === 1 && (
                              <>
                                <div className={styles.promoInfoItem}>
                                  <span className={styles.promoInfoLabel}>
                                    TOPPINGS SELECCIONADOS
                                  </span>
                                  {activePromo.toppings &&
                                    activePromo.toppings.map((topping) => {
                                      const index = toppings.findIndex(
                                        (el) => el.id === topping
                                      );
                                      return (
                                        <span className={styles.promoInfoValue}>
                                          {toppings[index].name}
                                        </span>
                                      );
                                    })}
                                </div>
                                <div className={styles.promoInfoItem}>
                                  <span className={styles.promoInfoLabel}>
                                    PRODUCTOS SELECCIONADOS
                                  </span>
                                  {activePromo.products &&
                                    activePromo.products.map((product) => {
                                      const index = products.findIndex(
                                        (el) => el.id === product
                                      );
                                      return (
                                        <span className={styles.promoInfoValue}>
                                          {products[index].name}
                                        </span>
                                      );
                                    })}
                                </div>
                              </>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      )}
      <Modal
        destroyOnClose
        visible={editorVisible}
        footer={false}
        closable={false}
        width={600}
        onCancel={closeEditor}
      >
        <Editor
          banner={banner}
          closeEditor={closeEditor}
          handleBanner={handleBanner}
        />
      </Modal>
      <Modal
        destroyOnClose
        closable={false}
        visible={editorPromoVisible}
        footer={false}
        onCancel={closeEditorPromo}
        width={600}
      >
        <EditorPromo
          close={closeEditorPromo}
          promo={activePromo}
          products={products}
          toppings={toppings}
          setActivePromo={setActivePromo}
        />
      </Modal>
    </div>
  );
};

export default FrontpageUI;
